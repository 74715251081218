<template>
  <div>
    <div
      v-if="fields?.price && isComparison"
      style="
        color: #635890;
        font-weight: 800;
        font-size: xx-large;
        width: 100%;
        text-align: center;
        margin: 16px 0 16px 0;
      "
    >
      {{ fields.price.price }} <sup>{{ fields.price.vat }}</sup>
    </div>
    <div
      :class="{ 'section-margin-small': index > 0 }"
      v-for="(detail, index) in fields?.bundleDetails"
      :key="index"
    >
      <div class="title">
        <div
          class="div-icon-image icon"
          :style="{
            backgroundImage: `url(${detail?.icon?.fields?.file?.url})`,
          }"
        ></div>
        <div>{{ detail?.categoryName }}</div>
      </div>

      <div class="benefits" v-for="benefit in detail?.benefits" :key="benefit">
        {{ benefit }}
      </div>
      <div
        class="benefits"
        v-for="benefit in detail?.extraBundleDetailsIfApplicable"
        :key="benefit"
      >
        {{ benefit }}
      </div>
    </div>
  </div>
</template>

<script>
import { getBundleByEntryId } from "@/util/bundlesUtil.js";

export default {
  name: "BundleDetailsLong",
  inject: ["$contentful"],
  props: {
    bundleId: {
      Type: String,
      default: "",
    },
    isComparison: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fields: {},
    };
  },
  watch: {
    bundleId: {
      async handler() {
        if (this.bundleId) {
          this.fields = await getBundleByEntryId(this.bundleId);
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang="scss">
.icon {
  height: 20px;
  width: 20px;
  margin-right: 8px;
  margin-top: -2px;
}

.title {
  margin-bottom: 10px;
  display: flex;
  font-size: $font-xs;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.benefits {
  color: #4f4f4f;
  text-align: center;
  font-size: $font-xxs;
  font-style: normal;
  font-weight: 450;
  line-height: 16px;
}
</style>
