<template>
  <FibrProductBanner :title="pageTitle" :imageUrl="bannerImageUrl">
  </FibrProductBanner>
  <div class="horizontal-page-margin vertical-page-margin">
    <div class="section-margin sub-heading">{{ fibrDifference.heading }}</div>
    <FeatureDescription
      :title="fibrDifference.title"
      :descriptionRt="fibrDifference.description"
      :isRichText="true"
    >
    </FeatureDescription>
    <ImageWithText
      class="section-margin"
      :imageUrl="investment.imageUrl"
      :isVideo="true"
    >
      <div class="fibr-addon-title">{{ investment.title }}</div>
      <RichText
        class="rich-text-style"
        :document="investment.description"
      ></RichText>
    </ImageWithText>
    <ImageWithText
      class="section-margin"
      :leftAlignImage="false"
      :imageUrl="ftth.imageUrl"
    >
      <div class="fibr-addon-title">{{ ftth.title }}</div>
      <RichText class="rich-text-style" :document="ftth.description"></RichText>
    </ImageWithText>
    <ImageWithText class="section-margin" :imageUrl="didYouKnow.imageUrl">
      <div class="fibr-addon-title">{{ didYouKnow.title }}</div>
      <RichText
        class="rich-text-style"
        :document="didYouKnow.description"
      ></RichText>
    </ImageWithText>
    <FeatureDescription
      class="section-margin"
      :title="getStarted.title"
      :descriptionRt="getStarted.description"
      :isRichText="true"
    >
    </FeatureDescription>
  </div>
</template>

<script>
import FibrProductBanner from "@/components/common/FibrProductBanner.vue";
import FeatureDescription from "@/components/common/FeatureDescription.vue";
import ImageWithText from "@/components/common/ImageWithText.vue";
import RichText from "@/components/common/RichText.vue";
import entryId from "@/config/entryid.json";
import { getEntryFromArray } from "@/util/contentUtil.js";

export default {
  name: "FibrFTTH",
  inject: ["$contentful"],
  components: {
    FibrProductBanner,
    FeatureDescription,
    ImageWithText,
    RichText,
  },
  data() {
    return {
      pageTitle: "",
      bannerImageUrl: "",
      fibrDifference: {
        title: "",
        description: {},
      },
      featureHighlights: [],
      investment: {
        title: "",
        description: {},
        imageUrl: "",
      },
      ftth: {
        title: "",
        description: {},
        imageUrl: "",
      },
      didYouKnow: {
        title: "",
        description: {},
        imageUrl: "",
      },
      getStarted: {
        title: "",
        description: {},
        imageUrl: "",
      },
    };
  },
  created() {
    this.$contentful.getEntry(entryId.fibrFtth.aboutFibrContent).then((val) => {
      const fields = val.fields.body;

      const titleInfo = getEntryFromArray(fields, entryId.fibrFtth.aboutFTTH);
      this.pageTitle = titleInfo?.title;
      this.bannerImageUrl = titleInfo?.backgroundImage?.fields?.file?.url;
      this.fibrDifference.description = titleInfo?.copy;

      const alivFibrDifference = getEntryFromArray(
        fields,
        entryId.fibrFtth.alivFibrDifference
      );
      this.fibrDifference.heading = alivFibrDifference?.heading;
      this.fibrDifference.title = alivFibrDifference?.title;
      this.fibrDifference.description = alivFibrDifference?.description;

      const investmentInfo = getEntryFromArray(
        fields,
        entryId.fibrFtth.investment
      );
      this.investment.title = investmentInfo?.title;
      this.investment.imageUrl = investmentInfo?.image?.fields?.file?.url;
      this.investment.description = investmentInfo?.descriptionRt;

      const ftthInfo = getEntryFromArray(fields, entryId.fibrFtth.ftth);
      this.ftth.title = ftthInfo?.title;
      this.ftth.imageUrl = ftthInfo?.image?.fields?.file?.url;
      this.ftth.description = ftthInfo?.descriptionRt;

      const didYouKnowInfo = getEntryFromArray(
        fields,
        entryId.fibrFtth.didYouKnow
      );
      this.didYouKnow.title = didYouKnowInfo?.title;
      this.didYouKnow.imageUrl = didYouKnowInfo?.image?.fields?.file?.url;
      this.didYouKnow.description = didYouKnowInfo?.descriptionRt;

      const getStartedInfo = getEntryFromArray(
        fields,
        entryId.fibrFtth.getStarted
      );
      this.getStarted.title = getStartedInfo?.title;
      this.getStarted.imageUrl = getStartedInfo?.image?.fields?.file?.url;
      this.getStarted.description = getStartedInfo?.descriptionRt;
    });
  },
};
</script>

<style scoped lang="scss">
.fibr-addon-title {
  color: #000;
  font-size: $font-l;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 32px;
}

.link {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  text-align: center;
  margin-top: 16px;
  cursor: pointer;
  color: #635890;
  font-size: $font-s;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
}

.rich-text-style {
  color: #4f4f4f;
  font-size: $font-s;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
}
</style>
