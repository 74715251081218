<template>
    <div class="terms-conditions">
      <h1>ALIV Fibr Acceptable Use Policy for Digital Television</h1>
  
      <section>
        <h2>This policy and you</h2>
        <ul>
          <li>
            We want to provide you with a great television experience at competitive prices. We’ve designed our TV service specifically for our residential customers, and we ask you to keep to some simple rules which we set out here. We also describe what we will do if you are unable to do so, which may include moving you to a more suitable package.
          </li>
          <li>
            By using our services, you agree to follow these rules. We may update them from time to time, and we’ll give you one month’s notice of any changes. If you don’t agree to this policy and/or any updated changes from time to time kindly contact us immediately to review your agreement for said services.
          </li>
          <li>
            You are responsible for ensuring that your family members and anyone else using your digital television service also follow these rules.
          </li>
        </ul>
      </section>
  
      <section>
        <h2>Where you can enjoy the TV service</h2>
        <ul>
          <li>
            Our TV service is for one private dwelling unit only. We define a dwelling unit as a single house, a single unit apartment, a single unit condominium or any other living accommodation or residence with one common entrance and exit, full amenities, and the rooms in the dwelling unit can be accessed from inside the unit.
          </li>
          <li>
            We define multi-dwelling units as separate housing units within one building or complex of buildings. Multi-dwelling units must have their own Cable Bahamas connections and separate subscriber accounts.
          </li>
          <li>
            If you rent out rooms within a single dwelling unit and you provide them with our TV service, you must pay a bulk hospitality rate and you are not eligible to receive our residential service.
          </li>
        </ul>
      </section>
  
      <section>
        <h2>Using our TV equipment</h2>
        <ul>
          <li>
            We provide you with set-top boxes and other equipment so that you can enjoy our television service. You must keep our equipment in the residence you’ve specified in your agreement with us, and must not move it to or share it with other dwellings or residences.
          </li>
          <li>
            You must not disturb, tamper with, reroute or interfere with any component of our cable system in case this damages the equipment or your television service. You must not connect our equipment to any other equipment or modify our equipment or cable installation.
          </li>
        </ul>
      </section>
  
      <section>
        <h2>Making this policy work</h2>
        <p>
          If we think you’ve breached this policy:
        </p>
        <ul>
          <li>we will look into the breach and may contact you to discuss the apparent breach (using your personal details to make contact with you)</li>
          <li>we may issue you with a warning letter or email</li>
          <li>we may suspend your television service (and perhaps the other services you receive from us) or move you to a more suitable package</li>
          <li>if this is the first time you’ve breached this policy, we will reconnect you once you have sent us a signed acknowledgement of our letter or email</li>
          <li>if you breach this policy for a second time, we may not reconnect you until our audit department visits the residence and conducts an investigation. We may charge you for the cost of this audit visit (minimum $100), and may also increase the amount of deposit required from you</li>
          <li>if you breach this policy again, we may terminate our contract with you and remove our equipment, and refuse to provide you with our services</li>
          <li>we may recover from you the costs that we have reasonably incurred as a result of the violation, including the costs of damage to our equipment or installation and the costs of collecting our equipment</li>
          <li>we may also take any other legal or technical action that we deem appropriate.</li>
        </ul>
        <p>
          If you agree in writing to stop committing the breach, we may restore your service.
        </p>
      </section>
  
      <section>
        <h2>Reporting and cooperation with law enforcement</h2>
        <ul>
          <li>
            In serious cases, we may report you to the police or another law enforcement agency, and give them your personal information. Sometimes, we might be asked by public bodies such as law enforcement agencies to give information about you. If those requests are lawful, we obey them. We may also cooperate with other television providers to enforce this policy and their policies.
          </li>
          <li>
            If you think that someone else has breached this policy and you want to report them to us, you should get in touch with us at: <a href="mailto:info@alivfibr.com">info@alivfibr.com</a>
          </li>
          <li>
            If you want to understand more about any of the points in this policy, please do contact us at: <a href="mailto:info@alivfibr.com">info@alivfibr.com</a>
          </li>
        </ul>
        <p>Revised: September 2022</p>
      </section>
    </div>
  </template>
  
  <script>
  export default {
    name: 'AcceptableUsePolicyDigitalTV',
  };
  </script>
  
  <style scoped>
  .terms-conditions {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
  }
  
  h1 {
    color: #635890;
  }
  
  h2 {
    color: #635890;
    margin-top: 20px;
  }
  
  ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  a {
    color: #635890;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  p {
    color: #666;
  }
  </style>
  