<template>
  <div class="horizontal-page-margin breadcrumbs">
    <router-link
      class="link active first"
      :class="hasBanner ? 'light' : 'dark'"
      :to="{ name: 'fibr-home' }"
    >
      {{ "Home" }}
    </router-link>
    <span v-for="item in breadcrumbs" :key="item.name">
      <span class="link" :class="hasBanner ? 'light' : 'dark'">
        {{ linkSeparator }}
      </span>
      <router-link
        class="link active"
        :class="hasBanner ? 'light' : 'dark'"
        :to="{ name: item.name }"
      >
        {{ item.label }}
      </router-link>
    </span>
    <span class="link active" :class="hasBanner ? 'light' : 'dark'">{{
      linkSeparator
    }}</span>
    <span class="link" :class="hasBanner ? 'light' : 'dark'">
      {{ currentRouteLabel }}
    </span>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import router from "@/router";

export default defineComponent({
  name: "BreadCrumbs",
  data() {
    return {
      routes: {},
      breadcrumbs: [],
      currentRouteLabel: "",
      linkSeparator: "/",
      hasBanner: false,
    };
  },
  watch: {
    $route: {
      handler() {
        this.breadcrumbs = [];
        const meta = this.$route.meta;
        this.hasBanner = meta.hasBanner;
        this.currentRouteLabel =
          meta.label === "Bundle" ? decodeURI(this.$route.params.bundle) : meta.label;
        if (meta && meta.breadcrumbs) {
          meta.breadcrumbs.forEach((item) => {
            const route = this.routes[item];
            if (route) {
              this.breadcrumbs.push({
                label: route.label,
                name: item,
              });
            }
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.routes = {};
    router.options.routes.forEach((route) => {
      if (route.name && route.meta) {
        this.routes[route.name] = route.meta;
      }
    });
  },
});
</script>

<style scoped lang="scss">
.breadcrumbs {
  position: absolute;
}

.link {
  font-size: $font-xs;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  margin-left: 5px;
}

.active {
  text-decoration: none;

  &.dark {
    color: #4f4f4f;
  }
}

.light {
  color: white;
}

.dark {
  color: black;
}

.first {
  margin-left: 0;
}
</style>
