<template>
  <div>
    <div class="heading-dark">{{ heading }}</div>
    <div class="place-holder">
      <div class="map-cover" v-if="showCover" @click="showCover = false"></div>
      <iframe :src="mapUrl" width="100%" height="100%"></iframe>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import entryId from "@/config/entryid.json";

export default defineComponent({
  name: "FibrServiceMap",
  inject: ["$contentful"],
  data() {
    return {
      title: "",
      heading: "",
      mapUrl:
        "https://www.google.com/maps/d/embed?mid=1Z0Wim9nEuWv-ncyXlbCHTQXtlso24GY&ehbc=2E312F",
      showCover: true,
    };
  },
  created() {
    this.$contentful.getEntry(entryId.fibrHome.aliveCoverageMap).then((val) => {
      this.title = val.fields.title;
      this.heading = val.fields.heading1;
    });
  },
});
</script>

<style scoped lang="scss">
.heading-dark {
  margin-bottom: 32px;
}

.place-holder {
  position: relative;
  height: 539px;
  background: #000000;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: $border-radius;
  overflow: hidden;
}

.map-cover {
  position: absolute;
  height: 100%;
  width: 100%;
  cursor: pointer;
}
</style>
