<template>
  <div
    class="product-card div-icon-image"
    :class="{ 'hover-effect-noshadow': routeName }"
    :style="{
      backgroundImage: `url(${imageUrl})`,
    }"
    @click="cardClicked"
  >
    <div class="card-contents">
      <div class="heading">{{ title }}</div>
      <div class="description">{{ description }}</div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import router from "@/router";

export default defineComponent({
  name: "SecondaryFeature",
  props: {
    title: {
      type: String
    },
    description: {
      type: String,
      default: "",
    },
    imageUrl: {
      type: String,
      default: "",
    },
    routeName: {
      type: String,
      default: "",
    },
  },
  methods: {
    cardClicked(e) {
      if (this.routeName) {
        e.stopPropagation();
        router.push({ name: this.routeName });
      }
    },
  },
});
</script>

<style scoped lang="scss">
.product-card {
  min-height: 700px;
  width: 100%;
  display: table;
  border-radius: $border-radius;
  padding: 20px;
}

.card-contents {
  display: table-cell;
  vertical-align: middle;
}

.heading {
  font-style: normal;
  font-weight: 700;
  font-size: $font-l;
  line-height: 40px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 8px;
}

.description {
  font-style: normal;
  font-weight: 450;
  font-size: $font-s;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
}
</style>
