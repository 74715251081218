<template>
  <div class="aliv-header" :class="{ unblurred: isExpanded }">
    <div>
      <div class="header-padding top-bar">
        <div class="top-bar-contents" v-if="!isMobile">
          <div class="left-navigation">
            <a
              class="topbar-links topbar-links-left"
              v-for="(item, index) in topBar?.menus"
              :key="index"
              :href="topBar?.menusLink?.[index]"
            >
              {{ item }}
            </a>
          </div>
          <div class="right-navigation">
            <a
              class="topbar-links topbar-links-right"
              v-for="(item, index) in topBar?.rightSideMenus"
              :key="index"
              :href="topBar?.rightMenusLink?.[index]"
            >
              {{ item }}
            </a>
          </div>
        </div>
      </div>
      <div class="header-padding nav-bar">
        <div class="left-block">
          <div
            class="logo div-icon-image"
            v-if="!isMobile"
            :style="{
              backgroundImage: `url(${navBar?.logo?.fields?.file?.url})`,
            }"
            @click="logoClicked"
          ></div>
          <div class="menu-items" v-if="!isMobile">
            <div
              class="menu-items"
              v-for="(menuItem, index) in navBar?.menus"
              :key="index"
            >
              <span
                class="menu-link"
                v-if="navBar?.menusLink?.[index] === `fibrdropdown`"
                @click="isExpanded = !isExpanded"
              >
                {{ menuItem }}
              </span>
              <a class="menu-link" v-else :href="navBar?.menusLink?.[index]">
                {{ menuItem }}
              </a>
            </div>
          </div>
        </div>
        <div class="middle-block">
          <div
            class="logo div-icon-image"
            v-if="isMobile"
            :style="{
              backgroundImage: `url(${navBar?.logo?.fields?.file?.url})`,
            }"
            style="margin-right: 0"
            @click="logoClicked"
          ></div>
        </div>
        <div class="right-block">
          <div
            class="menu-items"
            v-for="(menuItem, index) in navBar?.rightMenus"
            :key="index"
          >
            <a class="menu-link" :href="navBar?.rightMenusLink?.[index]">
              {{ menuItem }}
            </a>
          </div>
          <!-- <div class="search-div">
            <div
              class="search-icon div-icon-image"
              :style="{
                backgroundImage: `url(${navBar?.searchIcon?.fields?.file?.url})`,
              }"
            ></div>
            <div class="search-label">{{ navBar?.searchFieldText }}</div>
          </div> -->
          <div
            v-show="navBar?.loginButtonText"
            v-if="!isMobile"
            class="login-button"
            @click="login"
          >
            {{ navBar?.loginButtonText }}
          </div>
          <div
            class="cart-icon div-icon-image"
            :style="{
              backgroundImage: `url(${navBar?.cartIcon?.fields?.file?.url})`,
            }"
          ></div>
          <div
            class="cart-icon div-icon-image"
            v-if="isMobile"
            @click="isExpanded = !isExpanded"
          >
            <img
              v-if="!isExpanded"
              :src="require('@/assets/icons/hamburger.svg')"
            />
            <img v-else :src="require('@/assets/icons/cross.svg')" />
          </div>
        </div>
      </div>
      <div class="header-padding expanded-header" v-if="isExpanded">
        <div class="row">
          <div class="col-lg-2 col-md-4 col-sm-6">
            <div
              class="logo div-icon-image"
              :style="{
                backgroundImage: `url(${navBarExtended?.promo?.fields?.file?.url})`,
              }"
            ></div>
            <div class="expanded-copy">
              {{ navBarExtended?.copy }}
            </div>
            <div class="sign-up-button hover-effect-noshadow" @click="signup">
              {{ navBarExtended?.ctaButtonText }}
            </div>
          </div>
          <div
            class="col-lg-2 col-md-4 col-sm-6"
            v-for="entry in navBarExtended?.navBarExpandedEntries"
            :key="entry?.fields?.heading"
          >
            <div class="link-heading">
              {{ entry?.fields?.heading }}
            </div>
            <div
              class="header-link"
              v-for="(subheading, index) in entry?.fields?.subHeading"
              :key="index"
            >
              <a :href="entry?.fields?.linkSubHeading?.[index]">
                {{ subheading }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="blurred" @click="isExpanded = false"></div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import router from "@/router";
import entryId from "@/config/entryid.json";

export default defineComponent({
  name: "AlivHeader",
  inject: ["$contentful"],
  data() {
    return {
      topBar: {},
      navBar: {},
      navBarExtended: {},
      isExpanded: false,
    };
  },
  created() {
    this.$contentful.getEntry(entryId.header.topBar).then((val) => {
      this.topBar = val.fields;
    });
    this.$contentful.getEntry(entryId.header.navBar).then((val) => {
      this.navBar = val.fields;
    });
    this.$contentful.getEntry(entryId.header.navBarExtended).then((val) => {
      this.navBarExtended = val.fields;
    });
  },
  computed: {
    isMobile() {
      return window.innerWidth <= 900;
    },
  },
  methods: {
    logoClicked() {
      router.push({ name: "fibr-home" });
    },
    login() {
      // router.push({ name: "portal-login" });
      window.location.href = "https://portal.alivfibr.com/myfibr/login.aspx";
    },
    signup() {
      window.location.href = this.navBarExtended?.ctaUrl;
    },
  },
});
</script>

<style scoped lang="scss">
.aliv-header {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  position: relative;
}

@media only screen and (min-width: 576px) {
  .aliv-header {
    position: fixed;
  }
}

.unblurred {
  height: 100%;
  width: 100%;
  backdrop-filter: blur(3px);
}

.blurred {
  width: 100%;
  height: 100%;
}

.left-navigation {
  height: 100%;
}

.right-navigation {
  height: 100%;
  margin-left: auto;
}

.top-bar {
  height: 32px;
  background: #1f1f1f;
  color: #ffffff;
  padding-left: 48px;
  padding-right: 48px;
  display: flex;
}

.top-bar-contents {
  display: flex;
  width: 100%;
}

.topbar-links {
  color: #ffffff;
  font-size: $font-xxs;
  font-style: normal;
  font-weight: 450;
  line-height: 32px;

  &-left {
    margin-right: 24px;
  }

  &-right {
    margin-left: 24px;
  }
}

.nav-bar {
  height: 80px;
  border-bottom: 1px solid #efefef;
  display: flex;
  background-color: white;
  color: #000;
  font-size: $font-s;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.logo {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 30px;
  height: 38px;
  width: 85px;
  cursor: pointer;
}

.expanded-header {
  border-top: 1px solid #c8c8c8;
  border-bottom: 1px solid #c8c8c8;
  background: #fafafa;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding-top: 25px;
  padding-bottom: 25px;
}

.expanded-copy {
  color: #333;
  font-size: $font-xs;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  max-width: 200px;
  margin-top: 8px;
}

.sign-up-button {
  padding: 7px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: #635890;
  color: #fff;
  font-size: $font-xs;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  max-width: 180px;
  text-align: center;
  margin-top: 24px;
}

.link-heading {
  color: #000;
  font-size: $font-s;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 40px;
}

.header-link {
  color: #333;
  font-size: $font-xs;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  margin-top: 8px;
}

a {
  text-decoration: none;
  color: #333;
}

.middle-block {
  margin: auto;
}

.left-block {
  display: flex;

  @media only screen and (max-width: 900px) {
    width: 96px;
  }
}

.right-block {
  display: flex;
  white-space: nowrap;
}

.login-button {
  padding: 7px 16px;
  border-radius: 100px;
  background: #635890;
  color: white;
  font-size: $font-xs;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 48px;
  cursor: pointer;
}

.search-div {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 48px;
  cursor: pointer;
}

.search-icon {
  height: 16px;
  width: 16px;
  display: inline-block;
  top: 3px;
  position: relative;
}

.search-label {
  display: inline-block;
  margin-left: 8px;
}

.cart-icon {
  height: 24px;
  width: 24px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 24px;
  cursor: pointer;
}

.menu-items {
  display: inline-block;
  margin-top: auto;
  margin-bottom: auto;
}

.menu-link {
  color: #000;
  font-size: $font-s;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
}

.left-block .menu-items {
  margin-right: 48px;
}

.right-block .menu-items {
  margin-left: 48px;
}
</style>
