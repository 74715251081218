import { createStore } from "vuex";
import entryId from "@/config/entryid.json";
import { createClient } from "@/plugins/contentful.js";

export default createStore({
  state() {
    return {
      bundles: [],
      bundlesLoaded: false,
    };
  },
  mutations: {
    loadBundles(state) {
      state.bundlesLoaded = false;
      const contentful = createClient();

      contentful.getEntry(entryId.fibrBundles.main).then((val) => {
        const bundleEntries = val.fields.compareBundleSelector;
        const numBundles = bundleEntries.length;

        bundleEntries.forEach((entry) => {
          const bundle = entry.fields;
          bundle.sys = entry.sys;

          const bundleDetails = [];
          bundle.bundleDetails.forEach((detail) => {
            contentful.getEntry(detail.sys.id).then((val) => {
              bundleDetails.push(val.fields);
              bundleDetails.sort((a, b) => {
                const categoryA = a.metaTitle.split(" - ").pop();
                const categoryB = b.metaTitle.split(" - ").pop();
                if (categoryA < categoryB) {
                  return -1;
                }
                if (categoryA > categoryB) {
                  return 1;
                }
                return 0;
              });
            });
          });
          bundle.bundleDetails = bundleDetails;

          if (bundle.bundles.length > 0) {
            contentful.getEntry(bundle.bundles[0].sys.id).then((val) => {
              bundle.price = val.fields;
              delete bundle.bundles;
            });
          }

          state.bundles.push(bundle);

          const maxTimeout = 10000;
          const timeoutInterval = 500;
          let currentTimeout = 0;

          const checkBundlesStatus = setInterval(() => {
            currentTimeout += timeoutInterval;

            if (state.bundles.length === numBundles) {
              const allBundlesLoaded = state.bundles.reduce((total, item) => {
                return (
                  total &&
                  item.bundleDetails.length > 0 &&
                  bundle.price !== undefined
                );
              }, true);

              if (allBundlesLoaded || currentTimeout >= maxTimeout) {
                state.bundlesLoaded = true;
                clearInterval(checkBundlesStatus);
              }
            }
          }, timeoutInterval);
        });
      });
    },
  },
});
