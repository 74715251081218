<template>
    <div class="terms-conditions">
      <h1>ALIV Fibr Acceptable Use Policy for Landline Service</h1>
  
      <section>
        <h2>This policy and you</h2>
        <ul>
          <li>
            We think that CBL’s Voice (Landline) service is an excellent product, and we want you and other phone users to enjoy it properly. We can offer you great prices because we have targeted our voice service at residential customers. We want to make sure that our customers use the most appropriate package for their pattern of usage and that their use is within local and company guidelines, so we set out some simple rules for you to follow. We also explain what we may do if you don’t follow them.
          </li>
          <li>
            By using our services, you agree to follow these rules. We may update them from time to time, and we’ll give you one month’s notice of any changes. If you don’t agree to this policy or the changes, you must let us know and stop using our services.
          </li>
          <li>
            You are responsible for ensuring that your family members and anyone else using your phone service also follow these rules.
          </li>
        </ul>
      </section>
  
      <section>
        <h2>Who can use your Voice SERVICE</h2>
        <p>
          The voice service is for your residential use. If you have registered any extension phones with us, or your plan allows you to extend your service to another residential or mobile number, they can also be used for the voice service, and calls from them will be included in any limits in your phone package.
        </p>
      </section>
  
      <section>
        <h2>What you can use your voice service for</h2>
        <p>The voice service is designed for normal residential use, and we offer different plans suited to different patterns of use. The voice services should not be used for:</p>
        <ul>
          <li>operating a business, even if operating from your residence, unless you are working from home temporarily or you are a sole trader</li>
          <li>operating any other enterprise, including not-for-profit or governmental, unless you are working from home temporarily or you are a sole trader</li>
          <li>operating a call center</li>
          <li>resale to others</li>
          <li>auto-dialing or fax/voice blasts</li>
          <li>telemarketing</li>
          <li>without live dialogue, including use as a monitor, intercom or for transcription purposes</li>
          <li>any way that damages other customers’ ability to use the phone services or harms our operations.</li>
        </ul>
      </section>
  
      <section>
        <h2>How much you can use your phone</h2>
        <ul>
          <li>Our packages offer you different levels of use at different prices, including our packages with unlimited local calling and long distance calling. To avoid congestion on our network and to ensure that you’re on the most appropriate package for your usage:</li>
          <li>unlimited local calling means there are no limits on the volumes of calls. However, your usage should not be abnormal or try to get around the limitations in this Acceptable User Policy – for example, with frequent calls to a single number, calls of a very long duration, many conference calls, many calls made during business hours, many calls forwarded or transferred to another number, or calls terminated and redialled immediately afterwards. We define abnormal usage as above the level of 95% of other customers on the same package</li>
          <li>If you’re on a package with a fixed number of long distance minutes and you exceed this limit, any calls over the limit will be charged at our out of plan rates.</li>
        </ul>
      </section>
  
      <section>
        <h2>Act legally</h2>
        <p>Our phone service is a great way of keeping in touch with family and friends. However, you must comply with the laws of The Bahamas. For example, you (or any equipment that you use with your phone service) must not:</p>
        <ul>
          <li>carry out, promote or assist illegal activities, such as terrorism, fraud, pyramid schemes, human trafficking or child pornography</li>
          <li>promote or threaten violence towards anyone</li>
          <li>abuse or harass anyone, for example by making offensive, libellous, obscene, misleading or menacing comments</li>
          <li>encourage hatred, for example by making racist, sexist, discriminatory or ethnically offensive comments</li>
          <li>make hoax or nuisance calls</li>
          <li>violate anyone’s intellectual property or personal rights.</li>
        </ul>
      </section>
  
      <section>
        <h2>Making this policy work</h2>
        <ul>
          <li>look into the breach</li>
          <li>monitor your usage, for example by comparing your usage to other people’s usage on the same package</li>
          <li>contact you to discuss the apparent breach (using your personal details to make contact with you)</li>
          <li>transfer you to a more appropriate package, charge you for abnormal use or place restrictions on your usage</li>
          <li>suspend or terminate your voice service and other services you take from us</li>
          <li>issue you with a formal warning</li>
          <li>recover from you the costs that we have reasonably incurred as a result of the violation</li>
          <li>take any other legal or technical action that we deem appropriate.</li>
        </ul>
        <p>If you agree in writing to stop committing the breach, we may lift the restrictions or restore your service.</p>
      </section>
  
      <section>
        <h2>Reporting and cooperation with law enforcement</h2>
        <ul>
          <li>
            In serious cases, we may report you to the police or another law enforcement agency, and give them your personal information. Sometimes, we might be asked by public bodies such as law enforcement agencies to give information about you. If those requests are lawful, we obey them. We may also cooperate with other communication providers to enforce this policy and their policies.
          </li>
          <li>
            If you think that someone else has breached this policy and you want to report them to us, you should get in touch with us at: <a href="mailto:info@alivfibr.com">info@alivfibr.com</a>
          </li>
          <li>
            If you want to understand more about any of the points in this policy, please do contact us at: <a href="mailto:info@alivfibr.com">info@alivfibr.com</a>
          </li>
        </ul>
        <p>Revised: September 2022</p>
      </section>
    </div>
  </template>
  
  <script>
  export default {
    name: 'AcceptableUsePolicyLandline',
  };
  </script>
  
  <style scoped>
  .terms-conditions {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
  }
  
  h1 {
    color: #635890;
  }
  
  h2 {
    color: #635890;
    margin-top: 20px;
  }
  
  ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  a {
    color: #635890;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  p {
    color: #666;
  }
  </style>
  