<template>
  <div
    class="banner div-background-image"
    :style="{
      backgroundImage: `linear-gradient(90deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 69.17%), url(${imageUrl})`,
    }"
  >
    <div class="horizontal-page-margin banner-contents">
      <div class="heading">{{ title }}</div>
      <div class="description">{{ description }}</div>
      <a v-if="ctaLabel" :href="ctaUrl">
        <div class="cta-button hover-effect-noshadow">
          {{ ctaLabel }}
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "FibrProductBanner",
  props: {
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    imageUrl: {
      type: String,
      default: "",
    },
    ctaUrl: {
      type: String,
      default: "",
    },
    ctaLabel: {
      type: String,
      default: "",
    },
    minHeight: {
      type: String,
      default: "650px",
    },
  },
});
</script>

<style scoped lang="scss">
.banner {
  min-height: v-bind("minHeight");
  width: 100%;
  background-color: black;
  display: flex;
}

.banner-contents {
  display: block;
  margin-top: auto;
  margin-bottom: auto;
  position: relative;
}

.heading {
  font-style: normal;
  font-weight: 700;
  font-size: $font-xl;
  line-height: 56px;
  color: #ffffff;
  max-width: 550px;
  margin-bottom: 24px;
}

.description {
  font-style: normal;
  font-weight: 450;
  font-size: $font-s;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.8);
  max-width: 550px;
}

.cta-button {
  width: fit-content;
  padding: 12px 60px;
  border-radius: 100px;
  background: #635890;
  color: white;
  text-decoration: none;
  font-size: $font-s;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  margin-top: 30px;
}

a {
  text-decoration: none;
}
</style>
