<template>
  <div class="horizontal-page-margin section-margin-xlarge">
    <!-- header -->
    <div class="banner my-5">
      <h2 :style="{ color: '#000' }">{{ banner.title }}</h2>
      <p class="description">{{ banner.description }}</p>
    </div>

    <!-- main Deal -->
    <div class="my-2 main-deal-container position-relative">
      <p class="position-absolute limited-offer" v-if="mainDeal.isLimited">
        Limited Time Offer
      </p>
      <div class="banner-text">
        <h3 :style="{ color: '#635890' }">{{ mainDeal.title }}</h3>
        <p class="main-deal-description">{{ mainDeal.description }}</p>
        <button
          type="button"
          @click="redirect(mainDeal.url)"
          class="main-deal-cta"
        >
          {{ mainDeal.cta }}
        </button>
      </div>
      <div
        :style="{
          backgroundImage: `url(${mainDeal.imageUrl})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          height: '300px',
          width: '400px',
        }"
      ></div>
    </div>

    <!-- deals section text -->
    <h2 class="my-5" :style="{ color: '#635890' }">{{ dealSectionName }}</h2>

    <!-- Deals list -->
    <div class="deals-container">
      <div
        v-for="(deal, index) in deals"
        :key="deal.title"
        :class="['deal-item', { 'best-deal': deal.bestDeal }]"
        :style="getDealStyle(deal, index)"
      >
        <!-- Chip for best deal -->
        <div v-if="deal.bestDeal" class="best-deal-chip">Best Deal</div>

        <div
          class="deal-image"
          :style="{
            backgroundImage: `url(${deal.imageUrl})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
          }"
        ></div>
        <div class="deal-content">
          <h3>{{ deal.title }}</h3>
          <p class="deal-description">{{ deal.description }}</p>
          <button type="button" @click="openModal(deal)" class="deal-cta">
            See more
          </button>
        </div>
      </div>
    </div>

    <!-- Modal for "Get this deal" -->
    <div v-if="isModalOpen" class="modal-overlay">
      <div class="modal-content">
        <div
          class="modal-deal-image"
          :style="{
            backgroundImage: `url(${modalData.imageUrl})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            height: '200px',
            width: '100%',
          }"
        ></div>
        <h3>{{ modalData.title }}</h3>
        <p>{{ modalData.description }}</p>
        <button
          type="button"
          @click="redirect(modalData.url)"
          class="modal-cta"
        >
          Get this deal
        </button>
        <button type="button" @click="closeModal" class="modal-close">
          Close
        </button>
      </div>
    </div>

    <!-- coverage -->
    <CheckAliv class="section-margin"></CheckAliv>
  </div>
</template>

<script>
import CheckAliv from "@/components/common/CheckAliv.vue";
import entryId from "@/config/entryid.json";
import router from "@/router";

export default {
  name: "FibrDeals",
  inject: ["$contentful"],
  components: {
    CheckAliv,
  },
  data() {
    return {
      banner: {
        title: "",
        description: "",
      },
      mainDeal: {
        title: "",
        description: "",
        imageUrl: "",
        cta: "",
        url: "",
        isLimited: false,
      },
      deals: [],
      dealSectionName: "",
      isModalOpen: false,
      modalData: {
        title: "",
        description: "",
        imageUrl: "",
        url: "",
      },
    };
  },
  created() {
    this.$contentful
      .getEntry(entryId.fibrDealsPage.fibrDealsPageContent)
      .then((val) => {
        // set banner values
        this.banner.title = val.fields.body[0].fields.title;
        this.banner.description = val.fields.body[0].fields.description;
        // call the banner loader
        this.loadMainDeal(val);

        // call the deals loader
        this.loadDeals(val);
      });
  },
  methods: {
    redirect(url) {
      try {
        const parsedUrl = new URL(url, window.location.origin);

        if (parsedUrl.origin === window.location.origin) {
          // Internal URL
          router.push(parsedUrl.pathname);
        } else {
          // External URL
          window.location.href = url;
        }
      } catch (e) {
        // Fallback in case the URL is not well-formed
        router.push(url);
      }
    },
    loadMainDeal(val) {
      this.mainDeal.title = val.fields.body[1].fields.heading;
      this.mainDeal.description = val.fields.body[1].fields.description;
      this.mainDeal.isLimited = val.fields.body[1].fields.isLimited;
      this.mainDeal.imageUrl =
        val.fields.body[1].fields.bannerImage.fields.file.url;
      this.$contentful
        .getEntry(val.fields.body[1].fields.bannerCta.sys.id)
        .then((val) => {
          this.mainDeal.cta = val.fields.copy;
          this.mainDeal.url = val.fields.url;
        });
    },
    loadDeals(val) {
      // set deals section heading
      this.dealSectionName = val.fields.body[2].fields.dealsSection;

      // Fetch and process all deals
      Promise.all(
        val.fields.body[2].fields.dealsList.map((deal) =>
          this.$contentful.getEntry(deal.sys.id).then((parsedDeal) => ({
            title: parsedDeal.fields.heading,
            description: parsedDeal.fields.description,
            imageUrl: parsedDeal.fields.dealImage.fields.file.url,
            bestDeal: parsedDeal.fields.bestDeal,
            cta: parsedDeal.fields.cta.fields.copy,
            url: parsedDeal.fields.cta.fields.url,
          }))
        )
      ).then((deals) => {
        // Sort the deals to ensure bestDeal is first
        this.deals = deals.sort((a, b) => b.bestDeal - a.bestDeal);
      });
    },
    openModal(deal) {
      this.modalData = { ...deal };
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    getDealStyle(deal, index) {
      const isBestDeal = deal.bestDeal;
      const gapBetweenItems = 24; // The gap between items in pixels
      const totalHeight = 700; // The total height that the best deal and two regular deals should sum to

      if (isBestDeal) {
        return {
          gridColumn: "span 1",
          gridRow: "span 2",
          height: `${totalHeight}px`,
          backgroundColor: "rgba(255, 224, 178, 0.6)",
        };
      } else if (index === 1 || index === 2) {
        const remainingHeight = (totalHeight - gapBetweenItems) / 2;
        return {
          gridColumn: "span 1",
          gridRow: "span 1",
          height: `${remainingHeight}px`,
          minHeight: `${remainingHeight}px`,
          minWidth: "300px",
        };
      } else {
        return {
          gridColumn: "span 1",
          gridRow: "span 1",
          height: "350px",
          minHeight: "350px",
          minWidth: "300px",
        };
      }
    },
  },
};
</script>

<style scoped lang="scss">
.banner {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.description {
  color: gray;
  text-align: center;
  @media (min-width: 800px) {
    max-width: 40%;
  }
}

.banner-text {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-deal-description {
  color: gray;
  font-size: medium;
}

.main-deal-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 32px;
  background-color: rgba(224, 224, 224, 0.4);
  border-radius: 16px;
  transition: all 0.4s ease-in-out;

  @media (max-width: 800px) {
    flex-wrap: wrap-reverse;
    align-items: stretch;
    justify-content: center;
    padding: 2rem 32px;
  }
}

.main-deal-cta {
  border: none;
  border-radius: 10px;
  color: #635890;
  border: 1px solid #635890;
  align-self: flex-start;
  margin-top: 1rem;
  font-size: 19px;

  @media (max-width: 480px) {
    font-size: 4vw; /* Adjust button font size on smaller screens */
  }

  &:hover {
    background-color: #635890;
    color: white;
  }
}

.limited-offer {
  top: 22px;
  left: 56px;
  font-size: 14px;
  background: linear-gradient(to bottom right, #635890 10%, #ff6384);
  color: white;
  padding: 1px 6px;
  border-radius: 4px;

  @media (max-width: 480px) {
    font-size: 3.5vw; /* Adjust font size on smaller screens */
    left: 20px;
  }
}

.deals-container {
  display: grid;
  grid-template-columns: repeat(
    2,
    1fr
  ); /* Two columns, one for best deal, one for others */
  gap: 24px;
  margin-top: 2rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr; /* Single column on smaller screens */
  }
}

.deal-item {
  background-color: #f5f5f5;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transition: transform 0.3s ease;
  min-height: 350px;
  min-width: 300px;
  position: relative; /* To position the chip */

  @media (max-width: 480px) {
    min-width: 80%; /* Adjust minimum width on smaller screens */
  }
}

/* Chip for best deal */
.best-deal-chip {
  position: absolute;
  top: 10px;
  left: 20px;
  font-size: 12px;
  color: #635890;
  border: 3px solid #635890;
  padding: 2px 8px;
  border-radius: 16px;
  font-weight: 700;
  border-radius: 1rem;
  @media (max-width: 480px) {
    font-size: 3vw; /* Adjust font size on smaller screens */
  }
}

.deal-image {
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.best-deal .deal-image {
  height: 65%; /* 65% for the image in best deal */
}

.best-deal .deal-content {
  height: 35%; /* 35% for the text in best deal */
}

.deal-item:not(.best-deal) .deal-image {
  height: 50%; /* 50% for the image in regular deals */
}

.deal-item:not(.best-deal) .deal-content {
  height: 50%; /* 50% for the text in regular deals */
}

.deal-content {
  padding: 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 480px) {
    padding: 0.5rem; /* Reduce padding on smaller screens */
  }
}

.deal-content h3 {
  margin-bottom: 0.5rem;
  font-size: 1.2rem;

  @media (max-width: 480px) {
    font-size: 4vw; /* Adjust font size on smaller screens */
  }
}

.deal-content p {
  color: #666;
  margin-bottom: 1rem;
  flex-grow: 1;

  @media (max-width: 480px) {
    font-size: 3.5vw; /* Adjust font size on smaller screens */
  }
}

/* Clamped text for regular deals */
.deal-item:not(.best-deal) .deal-content p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; /* Prevents text from wrapping */
  max-height: 3.6rem; /* Ensures the text doesn't overflow */
}

/* Full text display for best deals */
.best-deal .deal-content p {
  white-space: normal; /* Allows text wrapping */
  overflow: visible;
  text-overflow: clip;
}

.deal-cta {
  border: none;
  border-radius: 4px;
  background-color: #635890;
  color: #fff;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  align-self: center;
  margin-top: auto; /* Pushes the button to the bottom */
  border-radius: 1rem;

  @media (max-width: 480px) {
    font-size: 3.5vw; /* Adjust button font size on smaller screens */
    padding: 0.3rem 0.8rem; /* Adjust padding on smaller screens */
  }
}

.deal-cta:hover {
  background-color: #4d4373;
}

.modal-cta {
  border: none;
  border-radius: 14px;
  background-color: #635890;
  color: #fff;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 1rem;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  max-width: 600px; /* Adjusted for larger content display */
  width: 100%;
}

.modal-deal-image {
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 200px;
}

.modal-cta {
  border: none;
  border-radius: 14px;
  background-color: #635890;
  color: #fff;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 1rem;
}

.modal-close {
  border: none;
  background-color: transparent;
  color: #000;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 1rem;
}

/* Your existing deal item styles */
.deal-item {
  background-color: #f5f5f5;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transition: transform 0.3s ease;
  min-height: 350px;
  min-width: 300px;
  position: relative; /* To position the chip */

  @media (max-width: 480px) {
    min-width: 80%; /* Adjust minimum width on smaller screens */
  }
}

/* Chip for best deal */
.best-deal-chip {
  position: absolute;
  top: 10px;
  left: 20px;
  font-size: 12px;
  color: #635890;
  border: 3px solid #635890;
  padding: 2px 8px;
  border-radius: 16px;
  font-weight: 700;
  border-radius: 1rem;
  @media (max-width: 480px) {
    font-size: 3vw; /* Adjust font size on smaller screens */
  }
}

.deal-image {
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.best-deal .deal-image {
  height: 65%; /* 65% for the image in best deal */
}

.best-deal .deal-content {
  height: 35%; /* 35% for the text in best deal */
}

.deal-item:not(.best-deal) .deal-image {
  height: 50%; /* 50% for the image in regular deals */
}

.deal-item:not(.best-deal) .deal-content {
  height: 50%; /* 50% for the text in regular deals */
}

.deal-content {
  padding: 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (max-width: 480px) {
    padding: 0.5rem; /* Reduce padding on smaller screens */
  }
}

.deal-content h3 {
  margin-bottom: 0.5rem;
  font-size: 1.2rem;

  @media (max-width: 480px) {
    font-size: 4vw; /* Adjust font size on smaller screens */
  }
}

.deal-content p {
  color: #666;
  margin-bottom: 1rem;
  flex-grow: 1;

  @media (max-width: 480px) {
    font-size: 3.5vw; /* Adjust font size on smaller screens */
  }
}

/* Clamped text for regular deals */
.deal-item:not(.best-deal) .deal-content p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; /* Prevents text from wrapping */
  max-height: 3.6rem; /* Ensures the text doesn't overflow */
}

/* Full text display for best deals */
.best-deal .deal-content p {
  white-space: normal; /* Allows text wrapping */
  overflow: visible;
  text-overflow: clip;
}

.deal-cta {
  border: none;
  border-radius: 4px;
  background-color: #635890;
  color: #fff;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  align-self: center;
  margin-top: auto; /* Pushes the button to the bottom */
  border-radius: 1rem;

  @media (max-width: 480px) {
    font-size: 3.5vw; /* Adjust button font size on smaller screens */
    padding: 0.3rem 0.8rem; /* Adjust padding on smaller screens */
  }
}

.deal-cta:hover {
  background-color: #4d4373;
}
</style>
