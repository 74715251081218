<template>
  <div class="terms-conditions">
    <h1>ALIV FIBR General Terms and Conditions</h1>

    <section>
      <p>
        These terms and conditions are divided into a general section that applies to every service (GENERAL TERMS AND CONDITIONS), and then into service-specific terms and conditions (SPECIFIC TERMS AND CONDITIONS). The services that we provide are voice telephony, television, and Internet services. Please read the general section and each section that applies to your service because the GENERAL TERMS AND CONDITIONS and the SPECIFIC TERMS AND CONDITIONS that apply to each service and device(s) that you acquire from us, will apply together. Your order and both of these sets of terms and conditions will constitute our contract. If there is any doubt about which of these provisions applies, the GENERAL TERMS AND CONDITIONS will always take precedence.
      </p>
    </section>

    <section>
      <h2>General Terms and Conditions</h2>

      <h3>1. The parties to this contract:</h3>
      <p>
        "CBL" is Cable Bahamas Ltd or "us", "we" or "our", otherwise known as "AlivFibr". Our registered office is located in Nassau, The Bahamas. "You", "your" or "end user" is the customer who is the account holder of our television, Internet, or voice telephony services ("the services"). This contract covers the services and any device(s) that is used in conjunction with the services if that device(s) is obtained from us.
      </p>

      <h3>2. This contract applies to your use of the services:</h3>
      <ul>
        <li>
          This contract comes into effect when you place the order, or when you activate any of the services or we activate any of them on your behalf. When this contract comes into effect it will be binding on you.
        </li>
        <li>
          You will be representing to CBL that you are at least 18 years of age and have authority to conclude this contract when you place the order, or if later, when you activate any of the services or we activate any of them for you. If you allow persons who are under the age of 18 to use our services or our device(s), you will be liable for all charges that they may incur.
        </li>
        <li>You may not resell any service to any other person.</li>
        <li>
          The services sold to you under this contract are for residential purposes only.
        </li>
        <li>
          The services must only be used for lawful purposes, and the transmission of any material or information arising from your use of the services must not violate any applicable law or regulation of The Commonwealth of The Bahamas or any other jurisdiction that you are transmitting the material or information to.
        </li>
        <li>
          If you do not pay for the services we may disconnect you or suspend your ability to use the services.
        </li>
        <li>
          If you use our website at
          <a href="https://www.bealiv.com/fibr" target="_blank">www.bealiv.com/fibr</a>
          or any related websites that we operate that may be linked to this website in order to view or order services or device(s), or to access any policies or other terms and conditions (including these GENERAL TERMS AND CONDITIONS and any SPECIFIC TERMS AND CONDITIONS), please ensure you are familiar with our Acceptable Use Policy.
        </li>
      </ul>

      <h3>3. This contract applies to your use of device(s):</h3>
      <ul>
        <li>
          You may not sell or transfer any device(s) unless you are entitled to take ownership of the device(s) and you have paid for the device(s) in full. A device(s) may include a set-top box, telephone, or broadband ONT, or anything used to provide television, data or telephony services that you acquire from us.
        </li>
        <li>
          Once we have installed the device(s), you may not move it without our permission unless it is moved within the same set of premises. If it is lost, damaged or stolen you must tell us immediately by contacting 606-2200 (New Providence), 602-8800 (Grand Bahama), 1-242-300-2200 (toll free) or via email at
          <a href="mailto:info@alivfibr.com">info@alivfibr.com</a>.
        </li>
        <li>
          You may not tamper with the device(s) in any way, including changing or removing the device(s)'s serial number, or the equipment identifier of the device(s), or performing a factory reset, or adjusting any component of the device(s) in any way. If the device(s) is not working, please contact 606-2200 (New Providence), 602-8800 (Grand Bahama), 1-242-300-2200 (toll free) or via email at
          <a href="mailto:info@alivfibr.com">info@alivfibr.com</a>.
        </li>
        <li>
          You will not own the device(s). However, you will be liable for the safety of the device(s) once it has been delivered to you. You will bear all the risk of loss due to theft or damage to the device(s) from this date.
        </li>
        <li>
          You understand that the device(s) rental charge shall continue in full force until the device(s) is returned to us. All repairs and modifications of the system shall be made by us or our authorised agents. If your rental box is not returned to us after your services are disconnected, you will be charged the full price of the device(s).
        </li>
        <li>
          If a device(s) has become obsolete because new technology has required that you should use a new device(s) to fully benefit from the services, we may require you to return your existing device(s) and obtain a new one. There may be fees associated with the upgrade.
        </li>
      </ul>

      <h3>4. Charges:</h3>
      <ul>
        <li>
          Each service will have its own price and the charges must be paid according to the terms and conditions for that service. When you order a service and if we agree to provide it, we will create an account for you in your name, and you will be responsible to pay all the charges on that account for all use of your service, even if you have not used the service yourself.
        </li>
        <li>
          Charges may include the price for a device(s) or a use fee, installation charges, call charges, or monthly service charges, as the case may be. We will notify you if there are any additional or other charges and if you have to pay a security deposit against any credit risk that we assume in providing a service or a device(s) to you. Effective 1 July 2018, all charges shall also be subject to VAT (Value Added Tax) at a rate of 10% as required by the Government of The Bahamas. This will be reflected on your contract and statements.
        </li>
        <li>
          Statements may be presented to you by email and you agree that an emailed or online statement will be adequate for delivery to you. If you do not dispute a statement under clause 13 within one month from the billing date, the statement will be deemed to be accepted by you.
        </li>
        <li>
          We may offer a refund or other compensation in certain limited cases where we do not meet the quality of service standards, including for example, where we do not reconnect you within 8 hours. Please see clause 6 of the GENERAL TERMS AND CONDITIONS.
        </li>
        <li>
          Charges are payable on the 20th of each month, and some charges may be payable in advance.
        </li>
        <li>
          Statements not paid by the due date shall be subject to a late payment charge on the overdue balance, or clause 10 may apply. A returned payment fee shall be charged to your account if your payment is rejected for any reason. We reserve the right to continue provision of the service on a prepaid basis only or to impose a credit limit on your account with respect to usage charges (billed and unbilled), or to suspend the service until we have received full payment of the outstanding charges.
        </li>
        <li>
          If you are receiving services under a promotion, when that promotion ends, the usual charges for the service will apply.
        </li>
        <li>
          If you are entitled to a discount on any services or if any savings can be made on any service that you are acquiring, we may advise you of this at the time when you acquire the service.
        </li>
      </ul>

      <h3>5. Changes to the contract or services:</h3>
      <ul>
        <li>
          Charges and terms and conditions may change. We will provide 1 calendar month's notice of any changes.
        </li>
        <li>
          You may migrate or upgrade a service on request to us and on payment of the relevant charge which is published on our website link
          <a href="https://www.bealiv.com/fibr">www.bealiv.com/fibr</a>.
        </li>
        <li>
          Changes to the terms and conditions will be published on our website along with the effective date when the changes will take effect. Once effective, the changes will be binding on you. You should regularly check the notices that we post to our website so that you are aware of any changes.
        </li>
        <li>
          If you do not want to continue the contract because of any change, you may cancel your services by giving us 1 month's notice us by telephone at 606-2200 (New Providence), 602-8800 (Grand Bahama) or toll free at 1-242-300-2200 or via email at
          <a href="mailto:info@alivfibr.com">info@alivfibr.com</a>.
        </li>
        <li>
          If you continue to use the services, we will accept that you have accepted the changes and we will continue to charge you at the new price or with the new change in terms and conditions.
        </li>
      </ul>

      <h3>6. Quality of service:</h3>
      <ul>
        <li>
          Our services are provided according to regulated quality of service standards. These standards may be reviewed on the website of the Utilities Regulation and Competition Authority (URCA) at
          <a href="https://www.urcabahamas.bs">www.urcabahamas.bs</a>.
        </li>
        <li>
          There may be occasions when the services or any one of them will not be available, for example, if we have to perform maintenance or upgrades. We will always try to give you as much notice as reasonably possible beforehand.
        </li>
        <li>
          We cannot warrant that the services will be available 24/7 because force majeure events such as adverse weather conditions, or interruptions in the power supply, civil disturbances, industrial disputes, or a problem with international services that we depend on, or equipment failures may cause interruptions or delays or other difficulties in accessing your services. We are not liable for any failure of the services where it results from force majeure because events of force majeure are not within our reasonable control.
        </li>
        <li>
          We will always try to make the services available as soon as possible.
        </li>
      </ul>

      <h3>7. Bundles:</h3>
      <ul>
        <li>
          If you have ordered a bundle of two or more services, please note that the price for each service may be different when it is provided as part of a bundle.
        </li>
        <li>
          If you wish to buy a bundle of services, you are entitled to ask for the individual charges for each service and we will provide this information to you.
        </li>
        <li>
          We may not be able to provide all of our services as a bundle in a particular area, so please check the availability of our services in your area before you place an order.
        </li>
        <li>
          We may require you to take a minimum contract term for a bundle or for one or more services in a bundle. Please ask for more information about this if you wish to purchase a bundle.
        </li>
        <li>
          If one or more services in a bundle are terminated or suspended, the standard monthly fee will apply to each remaining service.
        </li>
      </ul>

      <h3>8. Privacy and data protection:</h3>
      <ul>
        <li>
          You are making use of services that require certain information from you to verify that you are who you say you are and that your method of payment meets our criteria, therefore we will need to obtain certain personal information about you.
        </li>
        <li>
          We may use this information for purposes of contacting you to ask you about your experience of the services, to notify you about anything that is important in relation to your use of the services, for marketing purposes, to monitor your usage, or for quality control. We may contact you by email, SMS or other instant message or by calling you unless you ask us not to, in writing, but this will mean you may not receive important messages about changes to terms and conditions, prices, and new features.
        </li>
        <li>
          We will keep your information safely stored and will not share this with anyone (unless we are legally required to do so) without your permission, but we may need to share this with the third parties we contact to obtain a credit reference for you, and unless you tell us otherwise, you give us the right to share your information for this purpose by accepting these terms and conditions. You may always ask us for a copy of all of the information that we have stored for you.
        </li>
        <li>
          We will not store your credit card or bank account information unless you ask us to when you make payment, and you may withdraw your consent to this by notifying us by email at
          <a href="mailto:info@alivfibr.com">info@alivfibr.com</a>.
        </li>
        <li>
          If we are required to disclose your information or content that you have made available using our services, or other activities that you have carried out using any services or device(s) provided to you by us to law enforcement agencies or on receipt of any warrant or lawful direction, then this clause 8 shall not apply.
        </li>
      </ul>

      <h3>9. Intellectual property rights:</h3>
      <ul>
        <li>
          You acknowledge that we own the intellectual property rights in and to all of the services or, in the case of device(s), we are licensed to provide them to you and the licensor owns all of the intellectual property rights. None of the trademarks and intellectual property of CBL or our device(s) manufacturers may be used by you without our express permission. "Intellectual property" means, for example, ideas, designs, logos, components, formulae, copyright or patents.
        </li>
      </ul>

      <h3>10. Termination or suspension of services and removal of device(s):</h3>
      <ul>
        <li>
          You may terminate this contract at any time by giving us 1 month's notice in writing.
        </li>
        <li>
          If you do not pay your account on time or within 45 calendar days of the due date, we may suspend your services. If we intend to suspend the services we will give you 5 business days' notice, but you should take note that charges will still apply while the service is suspended. The notice will set out what you are required to do to avoid suspension. If you fail to pay your account even after the suspension notice has been served on you, we may disconnect you from the services on a further 5 days' notice.
        </li>
        <li>
          If you have not paid on time or at all for a device(s), or if we suspect or find out that you have tampered with the device(s) in any way or conducted activity which interferes with or compromises the integrity of our computer networks or equipment unauthorized use of shared services equipment, we may suspend your services and/or remove the device(s) from you and you give us permission to enter your premises to do so.
        </li>
        <li>
          If you use the services or your device(s) or both in breach of this contract or in a manner that is unlawful, or if you are not at least 18 years, we will terminate this contract immediately by giving you written notice, and we will terminate your use of the services and remove the device(s).
        </li>
        <li>
          In all cases, you will remain liable for all outstanding charges and late payment fees, and we will claim this from you if we take legal action against you, together with any legal fees we incur in doing so.
        </li>
        <li>
          If we reconnect your service or return your device(s) to you at our discretion, you will be required to pay a reconnection fee. We do not waive our right to collect the full balance owed to us by accepting partial payment.
        </li>
      </ul>

      <h3>11. Warranties:</h3>
      <ul>
        <li>
          If you received a device(s) from us for use with our services, then we will replace it if a material defect in the device(s) is discovered within a period of 90 days from the date on which we deliver the device(s) to you, but this is the limit of our warranty.
        </li>
        <li>
          This warranty does not apply to any damage caused by you or from ordinary wear and tear.
        </li>
        <li>
          By entering into this contract you acknowledge and agree that we are not the manufacturer of any device(s) and we do not make any representations (whether direct or indirect, express or implied, written or oral) in connection with any device(s).
        </li>
        <li>
          We will take reasonable precautions in line with general market practice to protect our services from unauthorized activity. However, we do not warrant that our services or our device(s) will be incapable of being hacked, tampered with, or that any other interference by third parties will not take place without our knowledge or without our consent. "Hacking" includes the illegal accessing of and/or subsequent manipulation of private information in any form.
        </li>
        <li>
          OTHER THAN THE LIMITED WARRANTY ABOVE, WE DO NOT OFFER ANY OTHER WARRANTIES WHETHER EXPRESS OR IMPLIED, AND WE SPECIFICALLY DISCLAIM ANY WARRANTY OF MERCHANTABILITY, FITNESS OF THE DEVICE(S) OR SERVICE FOR A SPECIFIC PURPOSE, OR INFRINGEMENT OF PATENTS OR COPYRIGHT, OR THAT THE DEVICE(S) OR SERVICE IS ERROR-FREE OR WILL MEET YOUR REQUIREMENTS.
        </li>
      </ul>

      <h3>12. Your and our liability:</h3>
      <ul>
        <li>
          WE ARE NOT LIABLE FOR AND YOU AGREE TO DEFEND, INDEMNIFY AND HOLD CBL, ITS DIRECTORS, ITS OFFICERS, AND ITS EMPLOYEES AGAINST ANY CLAIMS, LOSSES, DAMAGES, INJURY, FINES, PENALTIES, COSTS AND EXPENSES INCLUDING LEGAL FEES, THAT MAY ARISE AS A RESULT (WHETHER DIRECTLY OR INDIRECTLY) OF:
          <ul>
            <li>YOUR USE OR MISUSE OF THE SERVICES OR THE DEVICE(S);</li>
            <li>YOUR BREACH OF THIS CONTRACT OR ANY LAW; AND</li>
            <li>ANY COMPROMISE OF YOUR SERVICE OR PERSONAL DATA OR PRIVACY, HACKING OR SPAMMING THAT YOU MAY SUFFER.</li>
          </ul>
        </li>
        <li>
          BY ENTERING INTO THIS CONTRACT AND IF YOU ACQUIRE A DEVICE(S), YOU ACKNOWLEDGE AND AGREE THAT YOUR SOLE REMEDY IN RELATION TO ANY DEFECT IN THE DEVICE(S) OR SOFTWARE INCLUDING MANUFACTURE OR DESIGN SHALL BE AGAINST THE MANUFACTURER OF THE DEVICE(S) OR SOFTWARE AND CBL, ITS DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, SERVANTS, SUBSIDIARIES, AND AFFILIATES SHALL HAVE NO LIABILITY OR OBLIGATION TO YOU OF ANY KIND WHETHER IN CONTRACT OR FOR SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES INCURRED BY YOU OR ANYONE USING THE DEVICE(S) OR SOFTWARE AND WHETHER OR NOT CAUSED BY OUR NEGLIGENCE, TO THE EXTENT THAT THIS IS ALLOWED BY LAW.
        </li>
        <li>
          This clause will apply even after the contract has ended for any reason.
        </li>
        <li>
          You will not be liable for any claims, losses, damages, fines, penalties, costs and expenses under the above to the extent that we are proven to have contributed directly and substantially to the prejudice because we intentionally committed a wrongful act. In this case, our liability will be limited to 20% of the total charges incurred by you in the preceding 12-month period or the proportionate amount, as the case may be.
        </li>
      </ul>

      <h3>13. Dispute resolution and complaint handling:</h3>
      <ul>
        <li>
          You have the right to complain to us if you are not satisfied with the services you receive from us, or if you dispute the charges for those services.
        </li>
        <li>
          If you wish to dispute any amount that is included in your statement, or any other matter under this contract, you can send a written notice to
          <a href="mailto:info@alivfibr.com">info@alivfibr.com</a>, or to Cable Bahamas Ltd addressed to the Manager Customer Support, Customer Care Department, Robinson Road P.O. Box CB-13050 Nassau, The Bahamas with your account number, within 30 days of the date of your statement if the matter relates to your statement, or within 30 days for any other matter. You may also submit a complaint by telephone or by going to any of our customer service centers in New Providence, Grand Bahama, Abaco, or Eleuthera.
        </li>
        <li>
          You will need to provide as much information as possible to support your complaint including (whichever is relevant) the dates on which an event took place, the precise statement or charges in dispute, and records of any calls or correspondence.
        </li>
        <li>
          Once a complaint is received by us, we will issue a unique identifier code for the complaint so that you can track your complaint up to its resolution.
        </li>
        <li>
          A complaint will be acknowledged within five (5) business days of receipt by us, at which time we will advise when you can expect to receive a response to your complaint.
        </li>
        <li>
          In all cases, we will investigate your dispute and notify you of our findings within 30 business days.
        </li>
        <li>
          In the case where we find that your statement was incorrect, we will correct it and (i) if we overcharged you, we will refund you within 7 days, and (ii) if you were under-charged, the additional amount due will be added to the next month's statement.
        </li>
      </ul>

      <h3>14. General legal provisions:</h3>
      <ul>
        <li>
          These general terms and conditions and the terms and conditions of each service constitute the contract between us, which will be governed by the laws of The Commonwealth of The Bahamas, and we and you submit to its jurisdiction.
        </li>
        <li>
          If any term or condition set out here has to be deleted because it no longer applies, that will not affect the rest of these terms and conditions.
        </li>
        <li>
          Our failure to exercise any particular rights or provision of these terms and conditions shall not constitute a waiver of that right or provision, unless acknowledged and agreed to by us in writing.
        </li>
        <li>
          You agree that service of any notice referred to in or required by this contract will be effective if sent to the email address you provide to us. If you wish to give notice to us (other than as already set out in these terms and conditions), then you may do so by delivering a written notice to us at Cable Bahamas Ltd. Robinson Road at Marathon, P.O. Box CB-13050, Nassau, The Bahamas.
        </li>
        <li>
          We may assign this contract in whole or in part without your consent, but we will give you notice if we intend to do this.
        </li>
      </ul>
    </section>
  </div>
</template>

<script>
export default {
  name: "TermsAndConditions",
};
</script>

<style scoped>
.terms-conditions {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
}

h1 {
  color: #635890;
}

h2 {
  color: #635890;
  margin-top: 20px;
}

h3 {
  color: #635890;
  margin-top: 15px;
}

ul {
  list-style-type: disc;
  margin-left: 20px;
}

a {
  color: #635890;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

p {
  color: #666;
}
</style>
