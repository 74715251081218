<template>
  <div
    class="horizontal-page-margin vertical-page-margin section-margin-xlarge"
  >
    <FibrBundlesContainer></FibrBundlesContainer>
    <div class="section-margin">
      <div class="compare-title">{{ fields.comparisonSectionTitle }}</div>
      <div class="compare-copy">
        <RichText :document="fields.comparisonCopy"></RichText>
      </div>
      <div class="section-margin-small">
        <div class="row">
          <div
            class="col-md-4"
            v-for="(bundleId, index) in initalBundleIds"
            :key="bundleId"
          >
            <div class="bundle-card">
              <select v-model="initalBundleIds[index]">
                <option
                  v-for="bundle in allBundles"
                  :key="bundle.value"
                  :value="bundle.value"
                >
                  {{ bundle.label }}
                </option>
              </select>
              <BundleDetailsLong
                :bundleId="bundleId"
                :isComparison="true"
              ></BundleDetailsLong>
            </div>
          </div>
        </div>
      </div>
    </div>
    <BundleHardware class="section-margin"></BundleHardware>
  </div>
</template>

<script>
import BundleDetailsLong from "@/components/common/BundleDetailsLong.vue";
import RichText from "@/components/common/RichText.vue";
import FibrBundlesContainer from "@/components/fibr-home/bundles/FibrBundlesContainer.vue";
import BundleHardware from "@/components/common/BundleHardware.vue";
import entryId from "@/config/entryid.json";
import { getAllBundles } from "@/util/bundlesUtil.js";

export default {
  name: "FibrBundles",
  inject: ["$contentful"],
  components: {
    FibrBundlesContainer,
    RichText,
    BundleDetailsLong,
    BundleHardware,
  },
  data() {
    return {
      fields: {},
      initalBundleIds: [],
      allBundles: [],
    };
  },
  async created() {
    this.$contentful.getEntry(entryId.fibrBundles.main).then((val) => {
      this.fields = val.fields;

      this.fields.bundleSelection.forEach((item) => {
        this.initalBundleIds.push(item.sys.id);
      });
    });

    const allBundles = await getAllBundles();
    allBundles.forEach((item) => {
      this.allBundles.push({
        label: item.bundleName,
        value: item.sys.id,
      });
    });
  },
};
</script>

<style scoped lang="scss">
.compare-title {
  text-align: center;
  font-size: $font-l;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
}

.compare-copy {
  color: #4f4f4f;
  text-align: center;
  font-size: $font-xs;
  font-style: normal;
  font-weight: 450;
  line-height: 20px;
  max-width: 370px;
  margin-top: 8px;
  margin-left: auto;
  margin-right: auto;
}

.bundle-card {
  border-radius: $border-radius;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 20px 20px 40px 20px;
  height: 100%;
}

select {
  width: 80%;
  height: 44px;
  margin-bottom: 40px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  background: #fff;
  color: #635890;
  font-size: $font-xs;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  padding: 12px 20px;
  display: block;
}
</style>
