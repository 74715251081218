<template lang="html">
  <div>
    <ul class="tabs-header">
      <li
        v-for="(tab, index) in tabs"
        :key="tab.title"
        @click="selectTab(index)"
        :class="{ 'tab-selected': index == selectedIndex }"
      >
        {{ tab.title }}
      </li>
    </ul>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "AlivTabs",
  data() {
    return {
      selectedIndex: 0,
      tabs: [],
    };
  },
  mounted() {
    const checkTabs = setInterval(() => {
      if (this.tabs.length > 0) {
        this.selectTab(0);
        clearInterval(checkTabs);
      }
    }, 100);
  },
  methods: {
    selectTab(i) {
      this.selectedIndex = i;
      this.tabs.forEach((tab, index) => {
        tab.isActive = index === i;
      });
    },
  },
};
</script>

<style scoped lang="scss">
ul.tabs-header {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  color: #4f4f4f;
  font-size: $font-s;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
}

ul.tabs-header > li {
  padding: 15px 20px;
  margin: 0;
  display: inline-block;
  cursor: pointer;
}

ul.tabs-header > li.tab-selected {
  font-weight: 500;
  border-bottom: 4px solid #9b51e0;
  color: black;
}
</style>
