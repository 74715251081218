<template>
  <div class="horizontal-page-margin section-margin-xlarge">
    <FibrServiceMap></FibrServiceMap>
  </div>
</template>

<script>
import FibrServiceMap from "@/components/fibr-map/FibrServiceMap.vue";

export default {
  name: "FibrMap",
  components: {
    FibrServiceMap,
  },
};
</script>
