import store from "@/plugins/store.js";

async function getAllBundles() {
  return await checkBundlesStatus(() => {
    return store.state.bundles;
  });
}

async function getBundleByEntryId(entryId) {
  return await checkBundlesStatus(() => {
    return store.state.bundles.find((x) => x.sys.id === entryId);
  });
}

async function getBundleByName(name) {
  return await checkBundlesStatus(() => {
    return store.state.bundles.find((x) => x.bundleName === name);
  });
}

function checkBundlesStatus(callback) {
  return new Promise((resolve) => {
    if (store.state.bundlesLoaded) {
      resolve(callback());
    } else {
      const checkLoaded = setInterval(() => {
        if (store.state.bundlesLoaded) {
          clearInterval(checkLoaded);
          resolve(callback());
        }
      }, 500);
    }
  });
}

export { getAllBundles, getBundleByEntryId, getBundleByName };
