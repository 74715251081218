<template>
  <div
    class="bundle-card"
    :class="{
      'bundle-card-expanded': isOpen,
    }"
  >
    <div class="best-deal" v-if="fields?.bestDealBorder">
      {{ bestDeal }}
    </div>
    <div class="heading">{{ fields?.bundleName }}</div>
    <RichText
      class="bundle-description"
      :document="fields?.description"
    ></RichText>
    <div class="price">{{ fields?.price?.price }}</div>
    <div class="vat">{{ fields?.price?.vat }}</div>
    <div class="get-started-button hover-effect-noshadow" @click="openBundle">
      <RichText :document="fields?.price?.buttonText"></RichText>
    </div>
    <div v-show="isOpen">
      <div class="includes">{{ includes }}</div>
      <BundleDetailsLong
        class="details"
        :bundleId="fields?.sys?.id"
      ></BundleDetailsLong>
    </div>
    <div class="view-details-div" @click="isOpen = !isOpen">
      <div class="view-details">{{ arrowLabel }}</div>
      <img
        class="view-details-arrow"
        :class="{ 'view-details-arrow-open': isOpen }"
        :src="require('@/assets/icons/purple_arrow.svg')"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import router from "@/router";
import RichText from "@/components/common/RichText.vue";
import BundleDetailsLong from "@/components/common/BundleDetailsLong.vue";

export default defineComponent({
  name: "FibrBundleCard",
  inject: ["$contentful"],
  components: { RichText, BundleDetailsLong },
  props: {
    fields: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      bestDeal: "Best deal",
      includes: "Includes:",
      isOpen: false,
    };
  },
  computed: {
    arrowLabel() {
      return this.isOpen ? "See less" : "View details";
    },
  },
  methods: {
    openBundle() {
      router.push({
        name: "bundle-details",
        params: { bundle: encodeURI(this.fields.bundleName) },
      });
    },
  },
});
</script>

<style scoped lang="scss">
.bundle-card {
  height: 374px;
  border: 1px solid #e0e0e0;
  border-radius: $border-radius;
  display: block;
  position: relative;
  transition: height 400ms;
  overflow: hidden;
  padding-left: 32px;
  padding-right: 32px;

  &-expanded {
    height: 960px;
    transition: height 400ms;
  }
}

.best-deal {
  height: 28px;
  width: 130px;
  background: #ffc627;
  border-radius: 0 0 8px 8px;
  color: #4b3b0d;
  text-align: center;
  font-size: $font-xxs;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-left: auto;
  margin-right: auto;
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
}

img {
  position: relative;
  top: -2px;
}

.heading {
  height: 48px;
  margin-top: 48px;
  font-style: normal;
  font-weight: 700;
  font-size: $font-s;
  line-height: 24px;
  text-align: center;
  color: #000000;
  overflow: hidden;
}

.bundle-description {
  height: 40px;
  margin-top: 8px;
  color: #4f4f4f;
  text-align: center;
  font-size: $font-xs;
  font-style: normal;
  font-weight: 450;
  line-height: 20px;
  overflow: hidden;
}

.price {
  margin-top: 15px;
  font-style: normal;
  font-weight: 500;
  font-size: $font-l;
  line-height: 56px;
  text-align: center;
  color: #635890;
}

.vat {
  color: #635890;
  text-align: center;
  font-size: $font-xs;
  font-style: normal;
  font-weight: 450;
  line-height: 20px;
  height: 20px;
}

.get-started-button {
  font-style: normal;
  font-weight: 500;
  font-size: $font-xs;
  line-height: 42px;
  text-align: center;
  color: white;
  background: #635890;
  border-radius: 100px;
  width: 204px;
  height: 42px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

.view-details-div {
  cursor: pointer;
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;
}

.view-details {
  color: #635890;
  text-align: center;
  font-size: $font-xs;
  font-style: normal;
  font-weight: 450;
  line-height: 20px;
}

.view-details-arrow {
  transform: rotate(180deg);
  margin-left: auto;
  margin-right: auto;
  display: block;
  transition: transform 400ms;

  &-open {
    transform: rotate(0deg);
    transition: transform 400ms;
  }
}

.includes {
  margin-top: 40px;
  font-size: $font-s;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
}

.details {
  margin-top: 20px;
  margin-bottom: 80px;
}
</style>
