<template>
  <CarouselBanner></CarouselBanner>
  <div class="horizontal-page-margin">
    <FibrHeroProductContainer></FibrHeroProductContainer>
    <FibrBundlesContainer class="section-margin-small"></FibrBundlesContainer>
    <AboutFibr class="section-margin-small"></AboutFibr>
    <!-- testimonial space starts here -->
    <TestimonialCarousel
      :testimonials="testimonials"
      :v-if="testimonials.length > 0"
    />
    <!-- testimonial space ends here -->

    <FibrHomeFaq class="section-margin"></FibrHomeFaq>
    <CheckAliv class="section-margin"></CheckAliv>
  </div>
</template>

<script>
import FibrHeroProductContainer from "@/components/fibr-home/hero/FibrHeroProductContainer.vue";
import FibrBundlesContainer from "@/components/fibr-home/bundles/FibrBundlesContainer.vue";
import AboutFibr from "@/components/fibr-home/about-fibr/AboutFibr.vue";
import FibrHomeFaq from "@/components/fibr-home/home-faq/FibrHomeFaq.vue";
import CheckAliv from "@/components/common/CheckAliv.vue";
import CarouselBanner from "@/components/fibr-home/banner/CarouselBanner.vue";
import TestimonialCarousel from "@/components/home/TestimonialCarousel.vue";

export default {
  name: "FibrHome",
  inject: ["$contentful"],
  components: {
    FibrHeroProductContainer,
    FibrBundlesContainer,
    AboutFibr,
    FibrHomeFaq,
    CheckAliv,
    CarouselBanner,
    TestimonialCarousel,
  },
  data() {
    return {
      testimonials: [],
    };
  },
  created() {
    // fetch testimonials from Contentful
    this.$contentful
      .getEntries({
        content_type: "testimonial",
        limit: 3,
      })
      .then((response) => {
        this.testimonials = response.items.map((item) => ({
          title: item.fields.title,
          occupation: item.fields.occupation,
          rating: item.fields.rating,
          insights: item.fields.insights,
          avatarUrl: item.fields?.avatar?.fields.file.url,
          isHeader: item.fields.testimonialHead,
        }));
      })
      .catch((error) => {
        console.error("Error fetching testimonials:", error);
      });
  },
};
</script>
