<template lang="html">
  <div
    class="tab"
    :class="{ 'tab-background': showBackground, 'tab-border': !showBackground }"
    v-show="isActive"
  >
    <slot class="tab-contents"></slot>
  </div>
</template>

<script>
export default {
  name: "AlivTab",
  props: {
    title: {
      type: String,
      default: "Tab",
    },
    showBackground: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isActive: false,
    };
  },
  created() {
    this.$parent.tabs.push(this);
  },
};
</script>

<style scoped lang="scss">
.tab {
  position: relative;
  padding-top: $small-spacing;
  padding-bottom: $small-spacing;

  &-border {
    border-top: 1px solid #e0e0e0;
  }

  &-background:after {
    @include full-window-width;
    content: "";
    border-top: 1px solid #e0e0e0;
    background-color: #fafafa;
    z-index: -1;
  }
}
</style>
