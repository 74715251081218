<template>
  <div class="horizontal-page-margin vertical-page-margin">
    <div class="section-margin-xlarge">
      <div class="heading-dark">{{ fields?.title }}</div>
      <div class="title-sub-copy">
        {{ fields?.copy }}
      </div>
    </div>
    <div class="section-margin-small">
      <div
        style="
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          margin-top: 15px;
          text-align: center;
        "
      >
        <RichText :document="fields.tc"></RichText>
      </div>
      <AlivTabs>
        <AlivTab
          v-for="(bundleCategory, categoryName) in categorizedBundles"
          :key="categoryName"
          :title="categoryName"
        >
          <div>
            <div
              class="meta-bundle"
              v-for="(metaBundleData, metaBundleCategory) in bundleCategory"
              v-show="metaBundleData.length > 0"
              :key="metaBundleCategory"
            >
              <div class="meta-bundle-title">
                {{ bundlesTitle[metaBundleCategory] }}
              </div>
              <div class="meta-bundle-copy">
                {{ bundlesCopy[metaBundleCategory] }}
              </div>
              <div
                class="meta-bundle-details"
                v-if="metaBundleCategory != otherBundlesLabel"
              >
                <BundleCommonFeatures
                  :bundleDetails="bundlesInfo[metaBundleCategory]"
                  :showBroadband="
                    categoryName !== '1P Service (Broadband, TV or Voice)'
                  "
                  :showVoice="
                    categoryName !== '1P Service (Broadband, TV or Voice)' &&
                    categoryName !== 'TV + Broadband'
                  "
                ></BundleCommonFeatures>
              </div>
              <div class="section-margin-small">
                <div class="row">
                  <div
                    class="col-lg-3 col-sm-6"
                    v-for="(bundle, index) in metaBundleData"
                    :key="index"
                  >
                    <BundleDetailsCard
                      :bundle="bundle"
                      :showBundleCopy="metaBundleCategory !== otherBundlesLabel"
                    ></BundleDetailsCard>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AlivTab>
      </AlivTabs>
    </div>
  </div>
</template>

<script>
import AlivTab from "@/components/common/AlivTab.vue";
import AlivTabs from "@/components/common/AlivTabs.vue";
import BundleCommonFeatures from "@/components/fibr-bundles/BundleCommonFeatures.vue";
import BundleDetailsCard from "@/components/fibr-bundles/BundleDetailsCard.vue";
import entryId from "@/config/entryid.json";
import { getAllBundles } from "@/util/bundlesUtil.js";
import RichText from "@/components/common/RichText.vue";

export default {
  name: "AllBundles",
  inject: ["$contentful"],
  components: {
    AlivTabs,
    AlivTab,
    BundleCommonFeatures,
    BundleDetailsCard,
    RichText,
  },
  data() {
    return {
      fields: {},
      categorizedBundles: {},
      allBundlesLabel: "All",
      otherBundlesLabel: "Others",
      bundlesInfo: {},
      bundlesTitle: {},
      bundlesCopy: {},
    };
  },
  async created() {
    const allBundles = await getAllBundles();

    this.$contentful
      .getEntry(entryId.fibrBundles.allBundlesPage)
      .then((val) => {
        this.fields = val?.fields;
        const alivFibrVoice = {
          title: "AlivFibr Voice",
          image: val?.fields?.alivFibrVoiceImage?.fields?.file?.url,
          copy: val?.fields?.alivFibrVoiceCopy,
          highlights: val?.fields.alivFibrVoiceHighlights,
        };
        const alivFibrBroadband = {
          title: "AlivFibr Broadband",
          image: val?.fields?.alivFibrBroadbandImage?.fields?.file?.url,
          copy: val?.fields?.alivFibrBroadbandCopy,
          highlights: val?.fields.alivFibrBroadbandHighlights,
        };
        const alivFibrTVPro = {
          title: "AlivFibr Pro TV",
          image: val?.fields?.alivFibrTvImage?.fields?.file?.url,
          copy: val?.fields?.alivFibrTvCopy,
          highlights: val?.fields.alivFibrTvProHighlights,
        };
        const alivFibrTVPrime = {
          title: "AlivFibr Prime TV",
          image: val?.fields?.alivFibrTvImage?.fields?.file?.url,
          copy: val?.fields?.alivFibrTvCopy,
          highlights: val?.fields.alivFibrTvPrimeHighlights,
        };

        this.bundlesInfo = {
          Pro: {
            voice: alivFibrVoice,
            broadband: alivFibrBroadband,
            tv: alivFibrTVPro,
          },
          Prime: {
            voice: alivFibrVoice,
            broadband: alivFibrBroadband,
            tv: alivFibrTVPrime,
          },
          Infinite: {
            voice: alivFibrVoice,
            broadband: alivFibrBroadband,
            tv: alivFibrTVPrime,
          },
        };

        this.bundlesTitle = {
          Pro: val?.fields?.proBundlesTitle,
          Prime: val?.fields?.primeBundlesTitle,
          Infinite: val?.fields?.infiniteBundlesTitle,
          Others: val?.fields?.otherBundlesTitle,
        };

        this.bundlesCopy = {
          Pro: val?.fields?.proBundlesCopy,
          Prime: val?.fields?.primeBundlesCopy,
          Infinite: val?.fields?.infiniteBundlesCopy,
          Others: val?.fields?.otherBundlesCopy,
        };

        val?.fields?.bundleFilterCategories?.forEach((category) => {
          this.categorizedBundles[category] = {};
          this.categorizedBundles[category].Prime = [];
          this.categorizedBundles[category].Pro = [];
          this.categorizedBundles[category].Infinite = [];
          this.categorizedBundles[category][this.otherBundlesLabel] = [];
        });

        allBundles.forEach((item) => {
          const metaBundleCategory = item.metaBundleCategory
            ? item.metaBundleCategory
            : this.otherBundlesLabel;
          this.categorizedBundles[item.bundleTag][metaBundleCategory].push(
            item
          );
          this.categorizedBundles[this.allBundlesLabel][
            metaBundleCategory
          ].push(item);
        });
      });
  },
};
</script>

<style scoped lang="scss">
.heading-dark {
  margin-bottom: 16px;
}

.title-sub-copy {
  color: #4f4f4f;
  text-align: center;
  font-size: $font-s;
  font-style: normal;
  font-weight: 450;
  line-height: 24px;
  max-width: 370px;
  margin-left: auto;
  margin-right: auto;
}

.meta-bundle {
  margin-bottom: $medium-spacing;
}

.meta-bundle-title {
  font-size: $font-l;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  color: #000000;
}

.meta-bundle-copy {
  color: #4f4f4f;
  font-size: $font-xs;
  font-style: normal;
  font-weight: 450;
  line-height: 20px;
  margin-top: 8px;
}

.meta-bundle-details {
  margin-top: 20px;
}
</style>
