<template>
  <div v-html="htmlDoc"></div>
</template>

<script>
import { defineComponent } from "vue";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";

export default defineComponent({
  name: "RichText",
  props: {
    document: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      htmlDoc: "",
    };
  },
  watch: {
    document: {
      handler() {
        this.htmlDoc = documentToHtmlString(this.document).replace(
          /(?:\r\n|\r|\n)/g,
          "<br>",
        );
      },
      deep: true,
      immediate: true,
    },
  },
});
</script>
