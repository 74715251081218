<template>
    <div class="terms-conditions">
      <h1>ALIV Fibr Terms and Conditions: Residential Broadband</h1>
  
      <section>
        <p>
          These terms and conditions are divided into a general section that applies to every service, and then into service-specific terms and conditions. Please read the GENERAL TERMS AND CONDITIONS and each section that applies to your service because all the relevant terms will apply to you.
        </p>
      </section>
  
      <section>
        <h2>1. Personal use:</h2>
        <ul>
          <li>
            This service is only for your use and the use of the people that you authorise to use the service.
          </li>
          <li>
            We may apply bandwidth, data storage and other limitations to your use of the services, and you agree to comply with these limitations. Additional charges may be applied to any use in excess of the limitations.
          </li>
          <li>
            YOU MUST KEEP YOUR PASSWORDS AND OTHER INFORMATION NEEDED TO ACCESS THE SERVICES SAFE AND SECURE AT ALL TIMES.
          </li>
        </ul>
      </section>
  
      <section>
        <h2>2. Risks of using this service:</h2>
        <ul>
          <li>
            Any use of the Internet and data services involves risks of hacking or interception by third parties, malware and viruses that may corrupt or interfere with or damage your service and/or device(s). In making use of the service you acknowledge these risks and accept them and you indemnify and hold us harmless against all and any loss, liability, actions, costs, demands and damages of all and every kind (including direct, indirect, special or consequential damages), arising out of or in connection with an inability to use or access the service, or failure or delay in the performance of the service as a result of hacking or interception or other unauthorised intrusions into or alterations to the service or the device(s).
          </li>
          <li>
            You must take adequate precautions to protect your use of the service and any device(s) provided with it, in order to secure your hardware, software, and information you have created or stored using the service and/or the device(s).
          </li>
          <li>
            You acknowledge that the Internet is generally accessible and we do not control what is posted to or made available on the Internet (content), and we are therefore not liable for any inappropriate or misleading content that you may access whether deliberately or accidentally, or any consequence of your accessing this content.
          </li>
        </ul>
      </section>
  
      <section>
        <h2>3. Prohibitions that apply to your use of the services:</h2>
        <ul>
          <li>
            You may not undertake activities such as spamming or hacking or any cyber intrusion or cyber-attack of or other unlawful activity in relation to any other person’s device(s) or service, whether provided by us or not, during your use of the service and/or device(s). “Spamming” includes the use of electronic junk mail or unsolicited mail or contacting third parties without their permission.
          </li>
          <li>
            You may not transmit or distribute:
            <ul>
              <li>any unlawful materials including child or other pornography, libellous content, or material which is hateful or threatening or in any way encourages or constitutes conduct that is or could be unlawful.</li>
              <li>material that violates any other person’s copyright or intellectual property rights.</li>
              <li>information that seeks or encourage membership of a pyramid scheme or otherwise illegally solicits membership.</li>
              <li>material that suggests or represents to any other person that you have an identity that is not your own including using a digital signature that belongs to another person.</li>
            </ul>
          </li>
          <li>
            You may not:
            <ul>
              <li>use any tools designed to or which could have the effect of compromising the security of other networks or users including password-guessing programmes, cracking tools, packet sniffers or network-probing tools.</li>
              <li>overload your service or our network.</li>
              <li>send bulk or unsolicited emails using the service.</li>
              <li>deliberately use software that contains a virus or other harmful software that may be transmitted to third parties using our service.</li>
            </ul>
          </li>
          <li>
            It is forbidden to use the services in any way that may endanger children, or to use the services or allow the services to be used to expose them to disturbing and harmful materials including adult experiences. We recommend that you do not allow your children to use Internet services unsupervised.
          </li>
        </ul>
      </section>
  
      <section>
        <h2>4. Monitoring and take down:</h2>
        <ul>
          <li>
            We have the right to monitor your use of the services to measure your consumption of data and to review the content that you access or make available to third parties and by activating the service, you accept that we may do so, subject to clause 8 of the GENERAL TERMS AND CONDITIONS, except if (b) applies.
          </li>
          <li>
            We may take down or remove any content that you make available using our service if it should be in breach of this contract or any law, or constitute offensive or otherwise inappropriate content, or contravene clause 3c in our discretion, at any time. If a regulatory authority or the police or any other government agency with the relevant power (we will refer to them as “law enforcement officers”) requires us to remove content that you have posted using our service because we are caching or hosting that content, we will be required to comply with that order without notice to you.
          </li>
          <li>
            You may refer a matter to us in terms of clause 13 of the GENERAL TERMS AND CONDITIONS if you dispute our right to take down or grant access to your content to law enforcement officers.
          </li>
          <li>
            If we terminate your use of the service under clause 10 of the GENERAL TERMS AND CONDITIONS or otherwise as specified in these SPECIFIC TERMS AND CONDITIONS, we may delete all files, programmes, data and email messages associated with your account.
          </li>
        </ul>
      </section>
  
      <section>
        <h2>5. Installation of the service and/or a device(s):</h2>
        <ul>
          <li>
            In order to install the service and any device(s), we will need to access your premises and you give us permission to do so at a mutually convenient time.
          </li>
          <li>
            Installation will not guarantee that the service or device(s) will work immediately as you may need to activate the service or device(s) according to our instructions. If you cannot access the service or use the device(s) you should notify us immediately on 606-2200 (New Providence) or 602-8800 (Grand Bahama), 1-242-300-2200 (toll free) or via <a href="mailto:info@alivfibr.com">info@alivfibr.com</a>.
          </li>
          <li>
            If you are not using our device(s) to access the service, then we cannot guarantee when or if you will be able to access or use the service and device(s).
          </li>
          <li>
            You may not move the device(s) from the premises in which we installed it, without giving us written notice.
          </li>
          <li>
            After installation you must confirm that your device(s) is in full working order to our installer.
          </li>
          <li>
            In this section, a device(s) may include software that enables you to use the device(s).
          </li>
          <li>
            The services sold to you under this contract are for residential purposes only.
          </li>
        </ul>
      </section>
  
      <section>
        <h2>6. Charges:</h2>
        <ul>
          <li>
            You are required to make payment of the installation charge and the first month’s charges in advance, and prior to installation.
          </li>
          <li>
            We will invoice you at the time you place your order with us. Thereafter payment of the monthly charges must be made monthly in advance.
          </li>
          <li>
            All the other provisions of clause 4 of the GENERAL TERMS AND CONDITIONS will apply to charges.
          </li>
        </ul>
        <!-- <p>Revised: September 2022</p> -->
      </section>
    </div>
  </template>
  
  <script>
  export default {
    name: 'SpecificTermsBroadbandServices',
  };
  </script>
  
  <style scoped>
  .terms-conditions {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
  }
  
  h1 {
    color: #635890;
  }
  
  h2 {
    color: #635890;
    margin-top: 20px;
  }
  
  ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  a {
    color: #635890;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  p {
    color: #666;
  }
  </style>
  