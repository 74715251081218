<template>
  <div
    class="horizontal-page-margin vertical-page-margin section-margin-xlarge"
  >
    <ImageWithText
      :imageUrl="fields?.image?.fields?.file?.url"
      :leftAlignImage="false"
    >
      <div>
        <div class="main-heading">{{ fields?.faqCategoryTitle }}</div>
        <div class="main-copy">{{ fields?.description }}</div>
      </div>
    </ImageWithText>
    <div class="section-margin">
      <FaqCollapse
        v-for="(item, index) in fields?.faqs"
        :key="index"
        :title="item?.fields?.question"
      >
        <video
          class="my-5 w-100 h-100"
          :src="fields.video.fields.file.url"
          controls
          v-if="fields?.video && index === 0"
        ></video>
        <RichText
          class="rich-text-style"
          :document="item?.fields?.answer"
        ></RichText>
        <img
          v-show="getImageUrlByIndex(index)"
          :src="getImageUrlByIndex(index)"
          alt=""
          style="background-color: aqua; height: 400px; width: 99%"
        />
      </FaqCollapse>
    </div>
  </div>
</template>

<script>
import ImageWithText from "@/components/common/ImageWithText.vue";
import RichText from "@/components/common/RichText.vue";
import FaqCollapse from "@/components/common/FaqCollapse.vue";

export default {
  name: "FibrFAQ",
  inject: ["$contentful"],
  components: { ImageWithText, RichText, FaqCollapse },
  data() {
    return {
      fields: {},
      data: [],
    };
  },
  created() {
    this.$contentful.getEntry(this.$route.params.topic).then((val) => {
      val.fields.faqs.forEach((field, index) => {
        const image = this.getimageFromItem(field);
        if (image) {
          // Only push valid images
          this.data.push({ index, image });
        }
      });
      this.fields = val.fields;
    });
  },
  methods: {
    getimageFromItem(item) {
      const contentItem = item?.fields?.answer?.content?.find((c) => {
        return c?.data?.target?.fields?.file?.url;
      });
      return contentItem?.data?.target?.fields?.file?.url || null; // Return null if no valid URL
    },
    getImageUrlByIndex(index) {
      const dataItem = this.data.find((item) => item.index === index);
      return dataItem ? dataItem.image : null;
    },
  },
};
</script>

<style scoped lang="scss">
.main-heading {
  color: #000000;
  font-size: $font-l;
  font-style: normal;
  font-weight: 700;
  line-height: 56px;
}

.main-copy {
  color: #828282;
  font-size: $font-xs;
  font-style: normal;
  font-weight: 450;
  line-height: 20px;
  margin-top: 16px;
}

.rich-text-style {
  color: #333;
  font-size: $font-xs;
  font-style: normal;
  font-weight: 450;
  line-height: 20px;
}
</style>
