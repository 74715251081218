import { createWebHistory, createRouter } from "vue-router";
// import HomePage from '@/pages/home/HomePage.vue';
import FibrHome from "@/pages/fibr/FibrHome.vue";
import FibrProducts from "@/pages/fibr/FibrProducts.vue";
import FibrInternet from "@/pages/fibr/FibrInternet.vue";
import FibrPhone from "@/pages/fibr/FibrPhone.vue";
import FibrTV from "@/pages/fibr/FibrTV.vue";
import AboutFibr from "@/pages/fibr/AboutFibr.vue";
import FibrFTTH from "@/pages/fibr/FibrFTTH.vue";
import FibrBundles from "@/pages/fibr/FibrBundles.vue";
import AllBundles from "@/pages/fibr/AllBundles.vue";
import BundleDetails from "@/pages/fibr/BundleDetails.vue";
import FibrPortal from "@/pages/fibr/FibrPortal.vue";
import PortalLogin from "@/pages/fibr/PortalLogin.vue";
import FibrFAQ from "@/pages/fibr/FibrFAQ.vue";
import FibrFAQTopic from "@/pages/fibr/FibrFAQTopic.vue";
import FibrMap from "@/pages/fibr/FibrMap.vue";
import Newpage from "@/pages/fibr/deals.vue";
import Privacy from "@/pages/fibr/privacy.vue";
import GeneralTerms from "@/pages/fibr/generalTerms.vue";
import Landlineterms from "@/pages/fibr/landlineterms.vue";
import TelevisionTerms from "@/pages/fibr/televisionTerms.vue";
import Broadbandterms from "@/pages/fibr/broadbandterms.vue";
import TVAcceptableUsePolicy from "@/pages/fibr/TVAcceptableUsePolicy.vue";
import Broadbandacceptable from "@/pages/fibr/broadbandacceptable.vue";
import Landlineacceptable from "@/pages/fibr/landlineacceptable.vue";
import ALIVMobilePolicy from "@/pages/fibr/ALIVMobilePolicy.vue";
import Complaints from "@/pages/fibr/complaints.vue";
import RolloutMap from "@/pages/fibr/rolloutMap.vue";

const routes = [
  {
    path: "/",
    redirect: "/home/fibr", // Redirect root path to /home/fibr
  },
  {
    path: "/home/fibr",
    name: "fibr-home",
    component: FibrHome,
    meta: {
      breadcrumbs: [],
      label: "Fibr",
      hasBanner: true,
    },
  },
  {
    path: "/home/fibr/products",
    name: "fibr-products",
    component: FibrProducts,
    meta: {
      breadcrumbs: ["fibr-home"],
      label: "Products",
      hasBanner: true,
    },
  },
  {
    path: "/home/fibr/products/internet",
    name: "fibr-internet",
    component: FibrInternet,
    meta: {
      breadcrumbs: ["fibr-home", "fibr-products"],
      label: "Fibr Internet",
      hasBanner: true,
    },
  },
  {
    path: "/home/fibr/products/phone",
    name: "fibr-phone",
    component: FibrPhone,
    meta: {
      breadcrumbs: ["fibr-home", "fibr-products"],
      label: "Fibr Phone",
      hasBanner: true,
    },
  },
  {
    path: "/home/fibr/products/tv",
    name: "fibr-tv",
    component: FibrTV,
    meta: {
      breadcrumbs: ["fibr-home", "fibr-products"],
      label: "Fibr TV",
      hasBanner: true,
    },
  },
  {
    path: "/home/fibr/about",
    name: "about-fibr",
    component: AboutFibr,
    meta: {
      breadcrumbs: ["fibr-home"],
      label: "About",
      hasBanner: true,
    },
  },
  {
    path: "/home/fibr/ftth",
    name: "fibr-ftth",
    component: FibrFTTH,
    meta: {
      breadcrumbs: ["fibr-home"],
      label: "FTTH",
      hasBanner: true,
    },
  },
  // privacy policy
  {
    path: "/home/fibr/privacypolicy",
    name: "fibr-privacy",
    component: Privacy,
    meta: {
      breadcrumbs: ["fibr-privacy"],
      label: "privacy",
      hasBanner: false,
    },
  },
  // landline terms
  {
    path: "/home/fibr/landlineterms",
    name: "fibr-SpecificTermsVoiceServices",
    component: Landlineterms,
    meta: {
      breadcrumbs: ["fibr-landlineterms"],
      label: "landlineterms",
      hasBanner: false,
    },
  },
  // general terms and conditions
  {
    path: "/home/fibr/generalterms",
    name: "fibr-generalterms",
    component: GeneralTerms,
    // meta: {
    //   breadcrumbs: ["fibr-generalterms"],
    //   label: "generalterms",
    //   hasBanner: false,
    // },
  },
  // television terms
  {
    path: "/home/fibr/televisionterms",
    name: "fibr-landlineterms",
    component: TelevisionTerms,
    // meta: {
    //   breadcrumbs: ["fibr-televisionterms"],
    //   label: "televisionterms",
    //   hasBanner: false,
    // },
  },
  // broadband terms
  {
    path: "/home/fibr/broadbandterms",
    name: "fibr-broadbandterms",
    component: Broadbandterms,
    // meta: {
    //   breadcrumbs: ["fibr-broadbandterms"],
    //   label: "broadbandterms",
    //   hasBanner: false,
    // },
  },
  // TV Acceptable Use Policy
  {
    path: "/home/fibr/tv-acceptable-use-policy",
    name: "fibr-acceptable-use-policy",
    component: TVAcceptableUsePolicy,
    // meta: {
    //   breadcrumbs: ["fibr-acceptable-use-policy"],
    //   label: "acceptable-use-policy",
    //   hasBanner: false,
    // },
  },
  // Landline Acceptable Use Policy
  {
    path: "/home/fibr/landline-use-policy",
    name: "fibr-landline-use-policy",
    component: Landlineacceptable,
    // meta: {
    //   breadcrumbs: ["fibr-landline-use-policy"],
    //   label: "acceptable-use-policy",
    //   hasBanner: false,
    // },
  },
  // broadband Acceptable Use Policy
  {
    path: "/home/fibr/broadband-use-policy",
    name: "fibr-broadband-use-policy",
    component: Broadbandacceptable,
    // meta: {
    //   breadcrumbs: ["fibr-broadband-use-policy"],
    //   label: "acceptable-use-policy",
    //   hasBanner: false,
    // },
  },
  // mobile Policy
  {
    path: "/home/fibr/mobile-policy",
    name: "fibr-mobile-policy",
    component: ALIVMobilePolicy,
    // meta: {
    //   breadcrumbs: ["fibr-mobile-policy"],
    //   label: "mobile-use-policy",
    //   hasBanner: false,
    // },
  },
  // complaints page
  {
    path: "/home/fibr/complaints-procedure",
    name: "fibr-ComplaintsProcedure",
    component: Complaints,
  },
  {
    path: "/home/fibr/bundles",
    name: "fibr-bundles",
    component: FibrBundles,
    meta: {
      breadcrumbs: ["fibr-home"],
      label: "Bundles",
    },
  },
  {
    path: "/home/fibr/bundles/all",
    name: "all-bundles",
    component: AllBundles,
    meta: {
      breadcrumbs: ["fibr-home", "fibr-bundles"],
      label: "All Bundles",
    },
  },
  {
    path: "/home/fibr/bundle/:bundle",
    name: "bundle-details",
    component: BundleDetails,
    meta: {
      breadcrumbs: ["fibr-home", "fibr-bundles", "all-bundles"],
      label: "Bundle",
    },
  },
  {
    path: "/home/fibr/portal",
    name: "fibr-portal",
    component: FibrPortal,
    meta: {
      breadcrumbs: ["fibr-home"],
      label: "Portal",
      hasBanner: true,
    },
  },
  {
    path: "/home/fibr/portal/login",
    name: "portal-login",
    component: PortalLogin,
    meta: {
      breadcrumbs: ["fibr-home", "fibr-portal"],
      label: "Login",
    },
  },
  {
    path: "/home/fibr/faq",
    name: "fibr-faq",
    component: FibrFAQ,
    meta: {
      breadcrumbs: ["fibr-home"],
      label: "FAQs",
    },
  },
  {
    path: "/home/fibr/faq/:topic",
    name: "fibr-faq-topic",
    component: FibrFAQTopic,
    meta: {
      breadcrumbs: ["fibr-home", "fibr-faq"],
      label: "Single Article",
    },
  },
  {
    path: "/home/fibr/map",
    name: "fibr-map",
    component: FibrMap,
    meta: {
      breadcrumbs: ["fibr-home"],
      label: "Coverage Map",
    },
  },
  {
    path: "/home/fibr/rollout",
    name: "MapEmbed",
    component: RolloutMap, // Add the route for the map
    meta: {
      breadcrumbs: ["rollout-map"],
      label: "Rollout Map",
      hasBanner: false,
    },
  },
  {
    path: "/home/fibr/deals",
    name: "fibr-deals",
    component: Newpage,
    meta: {
      breadcrumbs: ["Deals-Bundles"],
      label: "Deals and Bundles",
    },
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: { name: "fibr-home" },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return { top: 0 };
  },
});

export default router;
