<template>
  <FibrProductBanner :title="pageTitle" :imageUrl="bannerImageUrl">
  </FibrProductBanner>
  <div class="horizontal-page-margin vertical-page-margin">
    <div class="section-margin sub-heading">{{ fibrDifference.heading }}</div>
    <FeatureDescription
      :title="fibrDifference.title"
      :description="fibrDifference.description"
    >
    </FeatureDescription>
    <div class="section-margin-small">
      <div class="row">
        <div
          v-for="feature in featureHighlights"
          :key="feature.id"
          class="col-md-4"
        >
          <TopBorderFibrCard
            :title="feature.title"
            :iconUrl="feature.iconUrl"
            :min-height="`196px`"
          >
          </TopBorderFibrCard>
        </div>
      </div>
    </div>
    <ImageWithText class="section-margin" :imageUrl="builtForPlay.imageUrl">
      <div class="fibr-addon-title">{{ builtForPlay.title }}</div>
      <RichText
        class="rich-text-style"
        :document="builtForPlay.description"
      ></RichText>
    </ImageWithText>
    <ImageWithText
      class="section-margin"
      :leftAlignImage="false"
      :imageUrl="builtUserFriendly.imageUrl"
    >
      <div class="fibr-addon-title">{{ builtUserFriendly.title }}</div>
      <RichText
        class="rich-text-style"
        :document="builtUserFriendly.description"
      ></RichText>
    </ImageWithText>
    <ImageWithText
      class="section-margin"
      :imageUrl="builtForHomeEverything.imageUrl"
    >
      <div class="fibr-addon-title">{{ builtForHomeEverything.title }}</div>
      <RichText
        class="rich-text-style"
        :document="builtForHomeEverything.description"
      ></RichText>
    </ImageWithText>
    <BundleHardware
      class="section-margin-large"
      :showAdditionalCopy="false"
    ></BundleHardware>
    <ImageWithText
      class="section-margin-large"
      :leftAlignImage="false"
      :imageUrl="alivFibrTelevision.imageUrl"
    >
      <div class="fibr-addon-title">{{ alivFibrTelevision.title }}</div>
      <RichText
        class="rich-text-style"
        :document="alivFibrTelevision.description"
      ></RichText>
    </ImageWithText>
    <FeatureDescription
      class="section-margin-large"
      :title="fibrFaqs.title"
      :description="fibrFaqs.description"
    >
    </FeatureDescription>
    <div>
      <div class="link">View all FAQs</div>
    </div>
    <div class="section-margin-small">
      <FaqCollapse
        v-for="item in fibrFaqs.items"
        :key="item.id"
        :title="item.question"
      >
        <RichText class="rich-text-style" :document="item.answer"></RichText>
      </FaqCollapse>
    </div>
  </div>
</template>

<script>
import FibrProductBanner from "@/components/common/FibrProductBanner.vue";
import FeatureDescription from "@/components/common/FeatureDescription.vue";
import TopBorderFibrCard from "@/components/common/TopBorderFibrCard.vue";
import ImageWithText from "@/components/common/ImageWithText.vue";
import RichText from "@/components/common/RichText.vue";
import FaqCollapse from "@/components/common/FaqCollapse.vue";
import entryId from "@/config/entryid.json";
import { getEntryFromArray } from "@/util/contentUtil.js";
import BundleHardware from "@/components/common/BundleHardware.vue";

export default {
  name: "AboutFibr",
  inject: ["$contentful"],
  components: {
    FibrProductBanner,
    FeatureDescription,
    TopBorderFibrCard,
    ImageWithText,
    RichText,
    FaqCollapse,
    BundleHardware,
  },
  data() {
    return {
      pageTitle: "",
      bannerImageUrl: "",
      fibrDifference: {
        title: "",
        description: "",
      },
      featureHighlights: [],
      builtForPlay: {
        title: "",
        description: {},
        imageUrl: "",
      },
      builtUserFriendly: {
        title: "",
        description: {},
        imageUrl: "",
      },
      builtForHomeEverything: {
        title: "",
        description: {},
        imageUrl: "",
      },
      alivFibrTelevision: {
        title: "",
        description: {},
        imageUrl: "",
      },
      fibrFaqs: {
        title: "",
        description: "",
        items: [],
      },
    };
  },
  created() {
    this.$contentful
      .getEntry(entryId.aboutFibr.aboutFibrContent)
      .then((val) => {
        const fields = val.fields.body;

        const titleInfo = getEntryFromArray(
          fields,
          entryId.aboutFibr.fullPotentialWithAlivFibr
        );
        this.pageTitle = titleInfo?.title;
        this.bannerImageUrl = titleInfo?.backgroundImage?.fields?.file?.url;
        this.fibrDifference.description = titleInfo?.copy;

        const alivFibrDifference = getEntryFromArray(
          fields,
          entryId.aboutFibr.alivFibrDifference.main
        );
        this.fibrDifference.heading = alivFibrDifference?.heading;
        this.fibrDifference.title = alivFibrDifference?.title;

        const builtForPlayInfo = getEntryFromArray(
          fields,
          entryId.aboutFibr.builtForPlay
        );
        this.builtForPlay.title = builtForPlayInfo?.title;
        this.builtForPlay.imageUrl = builtForPlayInfo?.image?.fields?.file?.url;
        this.builtForPlay.description = builtForPlayInfo?.descriptionRt;

        const builtUserFriendlyInfo = getEntryFromArray(
          fields,
          entryId.aboutFibr.builtUserFriendly
        );
        this.builtUserFriendly.title = builtUserFriendlyInfo?.title;
        this.builtUserFriendly.imageUrl =
          builtUserFriendlyInfo?.image?.fields?.file?.url;
        this.builtUserFriendly.description =
          builtUserFriendlyInfo?.descriptionRt;

        const builtForHomeEverythingInfo = getEntryFromArray(
          fields,
          entryId.aboutFibr.builtForHomeEverything
        );
        this.builtForHomeEverything.title = builtForHomeEverythingInfo?.title;
        this.builtForHomeEverything.imageUrl =
          builtForHomeEverythingInfo?.image?.fields?.file?.url;
        this.builtForHomeEverything.description =
          builtForHomeEverythingInfo?.descriptionRt;

        const alivFibrTelevisionInfo = getEntryFromArray(
          fields,
          entryId.aboutFibr.alivFibrTelevision
        );
        this.alivFibrTelevision.title = alivFibrTelevisionInfo?.title;
        this.alivFibrTelevision.imageUrl =
          alivFibrTelevisionInfo?.image?.fields?.file?.url;
        this.alivFibrTelevision.description =
          alivFibrTelevisionInfo?.descriptionRt;

        const fibrFaqsInfo = getEntryFromArray(
          fields,
          entryId.aboutFibr.fibrFaqs.fibrFaqsContent
        );
        this.fibrFaqs.title = fibrFaqsInfo.name;
        this.fibrFaqs.description = fibrFaqsInfo.copy;
      });

    this.$contentful
      .getEntry(entryId.aboutFibr.alivFibrDifference.fibrDirect)
      .then((val) => {
        const fields = val.fields;
        this.featureHighlights.push({
          id: 1,
          title: fields?.highlight,
          iconUrl: fields?.icon?.fields?.file?.url,
        });
      });
    this.$contentful
      .getEntry(entryId.aboutFibr.alivFibrDifference.speed1000Mbps)
      .then((val) => {
        const fields = val.fields;
        this.featureHighlights.push({
          id: 2,
          title: fields?.highlight,
          iconUrl: fields?.icon?.fields?.file?.url,
        });
      });
    this.$contentful
      .getEntry(entryId.aboutFibr.alivFibrDifference.upgradedModem)
      .then((val) => {
        const fields = val.fields;
        this.featureHighlights.push({
          id: 3,
          title: fields?.highlight,
          iconUrl: fields?.icon?.fields?.file?.url,
        });
      });

    entryId.aboutFibr.fibrFaqs.faq.forEach((item) => {
      this.$contentful.getEntry(item).then((val) => {
        const fields = val.fields;
        this.fibrFaqs.items.push({
          question: fields.question,
          answer: fields.answer,
        });
      });
    });
  },
};
</script>

<style scoped lang="scss">
.fibr-addon-title {
  color: #000;
  font-size: $font-l;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 32px;
}

.link {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  text-align: center;
  margin-top: 16px;
  cursor: pointer;
  color: #635890;
  font-size: $font-s;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
}

.rich-text-style {
  color: #4f4f4f;
  font-size: $font-s;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
}
</style>
