<template>
  <a :href="url">
    <div class="product-card hover-effect">
      <div>
        <div
          class="icon div-icon-image"
          :style="{ backgroundImage: `url(${icon})` }"
        ></div>
        <div class="heading">{{ title }}</div>
        <div class="description">{{ description }}</div>
      </div>
    </div>
  </a>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "FibrNavCard",
  props: {
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    url: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    textColor: {
      type: String,
      default: "#000000",
    },
    cardMinHeight: {
      type: String,
      default: "169px",
    },
  },
});
</script>

<style scoped lang="scss">
a {
  text-decoration: none;
}

.heading {
  font-style: normal;
  font-weight: 700;
  font-size: $font-m;
  line-height: 32px;
  text-align: center;
  color: #211903;
  margin-bottom: 6px;
}

.description {
  font-style: normal;
  font-weight: 450;
  font-size: $font-xs;
  line-height: 18px;
  text-align: center;
  color: v-bind("textColor");
}

.icon {
  height: 40px;
  width: 40px;
  margin-bottom: 5px;
  margin-left: auto;
  margin-right: auto;
}

.product-card {
  min-height: v-bind("cardMinHeight");
  height: 100%;
  background-color: white;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: $border-radius;
  padding: 35px 20px;
}
</style>
