<template>
  <div class="container">
    <div class="title" @click="isExpanded = !isExpanded">
      <div>{{ title }}</div>
      <div class="icon" :class="{ 'invert-icon': isExpanded }">
        <img :src="require('@/assets/icons/collapse_arrow.svg')" />
      </div>
    </div>
    <Collapse :when="isExpanded">
      <div class="text-content">
        <slot></slot>
      </div>
    </Collapse>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Collapse } from "vue-collapsed";

export default defineComponent({
  name: "FaqCollapse",
  components: {
    Collapse,
  },
  props: {
    title: {
      type: String,
      default: "Title",
    },
  },
  data() {
    return {
      isExpanded: false,
    };
  },
  computed: {
    isSmallScreen() {
      return window.innerWidth <= 760;
    },
  },
});
</script>

<style scoped lang="scss">
.text-content {
  padding: 0 25px 25px 25px;
}

.container {
  border-bottom: 1px solid #bdbdbd;
}

.title {
  color: #000;
  font-size: $font-s;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 25px;
  cursor: pointer;
  display: flex;
}

.icon {
  background-image: require("@/assets/icons/collapse_arrow.svg");
  position: relative;
  margin-left: auto;
  height: 24px;
  width: 24px;
  transition: transform 200ms;
}

.invert-icon {
  transform: rotate(180deg);
  transition: transform 200ms;
}
</style>
