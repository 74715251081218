<template>
  <div class="terms-conditions">
    <h1>ALIV Fibr Specific Terms and Conditions: Voice Services</h1>

    <section>
      <p>
        These terms and conditions are divided into a general section that
        applies to every service, and then into service-specific terms and
        conditions. Please read the GENERAL TERMS AND CONDITIONS and each
        section that applies to your service because all of the relevant terms
        will apply to you.
      </p>
    </section>

    <section>
      <h2>1. Terms of use of voice services:</h2>
      <ul>
        <li>
          You may not use the service device(s) for auto-dialling, continuous or
          extensive call forwarding, telemarketing (including, without
          limitation, charitable or political solicitation or polling), fax or
          voicemail broadcasting, or other similar activities.
        </li>
        <li>
          You may not take any action that would result in or be intended to
          hack or disrupt the service.
        </li>
      </ul>
    </section>

    <section>
      <h2>2. Specific charging terms for Voice services:</h2>
      <ul>
        <li>
          You may choose a plan of minutes and charges from the various
          offerings that we make available at the time you enter into this
          contract with us.
        </li>
        <li>
          If you exceed the minutes of your selected plan, you will be charged
          the applicable rate per minute for any excess.
        </li>
        <li>
          You acknowledge that we may require you to pay higher rates for all
          services during any period in which we determine, in our sole and
          absolute discretion, that your use of the service or device(s) was or
          is inconsistent with normal residential use. Please refer to our
          reasonable use policy for Voice landline phone services at
          <a href="https://www.alivfibr.com" target="_blank">www.alivfibr.com</a
          >.
        </li>
        <li>
          REV understands that its products and services are not immune from
          unauthorized use or fraudulent intrusion and that third parties may
          commit toll fraud. In no event shall REV be liable to you for any
          claim relating in any way to unauthorized use of Voice or toll fraud.
        </li>
      </ul>
    </section>

    <section>
      <h2>3. Directory services:</h2>
      <ul>
        <li>
          We allow you to list your telephone number that corresponds to the
          service we are providing to you, on our website, free of charge.
        </li>
        <li>
          If your number is listed on our website, you may request us to remove
          it at any time by giving us notice in writing to
          <a href="mailto:info@alivfibr.com">info@alivfibr.com</a>.
        </li>
        <li>
          If your number is listed on our website, it will be publicly available
          so clause 8 of the GENERAL TERMS AND CONDITIONS will not apply, and
          you may be at risk of spamming, or uninvited telephone calls, or an
          invasion of your privacy, but we will not be liable to you for the
          consequences of this listing. “Spamming” includes the use of
          electronic junk mail or unsolicited mail or contacting third parties
          without their permission.
        </li>
      </ul>
      <p>Revised: September 2022</p>
    </section>
  </div>
</template>

<script>
export default {
  name: "SpecificTermsVoiceServices",
};
</script>

<style scoped>
.terms-conditions {
  max-width: 800px;
  margin: auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
}

h1 {
  color: #635890;
}

h2 {
  color: #635890;
  margin-top: 20px;
}

ul {
  list-style-type: disc;
  margin-left: 20px;
}

a {
  color: #635890;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

p {
  color: #666;
}
</style>
