<template>
  <div>
    <div
      class="aliv-container div-background-image"
      :style="{
        backgroundImage: `url(${fields?.backgroundImage?.fields?.file?.url})`,
      }"
    >
      <div class="content">
        <div class="top-heading">{{ fields?.title }}</div>
        <div class="heading">{{ fields?.heading1 }}</div>
        <div class="copy">{{ fields?.heading2 }}</div>
        <a :href="fields?.cta?.fields?.url">
          <div class="button hover-effect-noshadow">
            {{ fields?.cta?.fields?.name }}
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import entryId from "@/config/entryid.json";

export default defineComponent({
  name: "CheckAliv",
  inject: ["$contentful"],
  data() {
    return {
      fields: {},
    };
  },
  created() {
    this.$contentful.getEntry(entryId.fibrHome.aliveCoverageMap).then((val) => {
      this.fields = val.fields;
    });
  },
});
</script>

<style scoped lang="scss">
.aliv-container {
  position: relative;
  display: flex;
  min-height: 320px;
  padding-right: 175px;

  @media (max-width: 1000px) {
    background-image: none !important;
    background: linear-gradient(90deg, #3a1559 0%, #06e7e2 100%) !important;
  }

  &:after {
    @include full-window-width;
    width: calc(99.3dvw) !important;
    content: "";
    z-index: -1;
    background: linear-gradient(90deg, #3a1559 50%, #06e7e2 50%);
  }
}

@media (max-width: 1000px) {
  .aliv-container {
    padding-right: 40px;
  }
}

.content {
  margin-top: auto;
  margin-bottom: auto;
  max-width: 560px;
}

.top-heading {
  font-size: $font-xs;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: capitalize;
  color: white;
}

.heading {
  font-size: $font-l;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  color: white;
  margin-top: 8px;
}

.copy {
  font-size: $font-xs;
  font-style: normal;
  font-weight: 450;
  line-height: 20px;
  color: #f2f2f2;
  margin-top: 8px;
}

a {
  text-decoration: none;
}

.button {
  font-size: $font-xs;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  text-align: center;
  color: white;
  border-radius: 100px;
  border: 2px solid white;
  padding: 10px 16px;
  width: 145px;
  margin-top: 28px;
}
</style>
