<template>
  <div>
    <h3></h3>
    <div class="map-container">
      <iframe
        v-if="ipAllowed"
        src="https://www.google.com/maps/d/u/1/embed?mid=14-81_7aOlSg2ULrfgPlw83BQkdPnD2k&ehbc=2E312F"
        width="100%"
        height="100%"
      ></iframe>
      <div v-else class="access-denied-message">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          class="access-denied-icon"
        >
          <path
            fill="#721c24"
            d="M12 0C5.373 0 0 5.373 0 12c0 6.627 5.373 12 12 12s12-5.373 12-12C24 5.373 18.627 0 12 0zm0 22C6.48 22 2 17.52 2 12S6.48 2 12 2s10 4.48 10 10-4.48 10-10 10zm-1-15h2v6h-2V7zm0 8h2v2h-2v-2z"
          />
        </svg>
        <h2>Access Denied</h2>
        <p>You do not have permission to view this page.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MapEmbed",

  data() {
    return {
      ipAllowed: false,
      loading: true,
      error: null,
      allowedIPs: [process.env.VUE_APP_ALLOWED_IP], // Replace with your allowed IPs
    };
  },

  async created() {
    try {
      const userIP = await this.getUserIP();
      this.ipAllowed = this.isIPAllowed(userIP);
    } catch (error) {
      console.error("Error fetching user IP:", error);
      this.error = "Unable to fetch your IP address.";
    } finally {
      this.loading = false; // Set loading to false after attempting to fetch IP
    }
  },

  methods: {
    async getUserIP() {
      const response = await fetch("https://api.ipify.org?format=json"); // Fetch the user's public IP directly
      if (!response.ok) throw new Error("Network response was not ok");
      const data = await response.json();
      return data.ip; // Return the fetched IP
    },

    isIPAllowed(ip) {
      return this.allowedIPs.includes(ip);
    },

    requestGeolocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            console.log("Geolocation granted:", position);
            // You can use position.coords.latitude and position.coords.longitude here
          },
          (error) => {
            console.error("Geolocation error:", error);
            this.error = "Unable to access your location.";
          }
        );
      } else {
        this.error = "Geolocation is not supported by this browser.";
      }
    },
  },
};
</script>

<style scoped>
h3 {
  height: 0.1px;
}

.map-container {
  display: flex;
  justify-content: center;
  align-items: center; /* Centering vertically */
  padding: 0 20px 0 20px;
  margin-top: 5rem;
  height: 75vh; /* Allow dynamic height */
}

iframe {
  border-radius: 10px; /* Rounded corners for the iframe */
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1); /* Soft shadow effect */
}

.access-denied-message {
  text-align: center;
  padding: 30px; /* Increased padding for a better layout */
  background-color: #f8d7da; /* Light red background */
  color: #721c24; /* Dark red text */
  border-radius: 10px; /* Rounded corners */
}

.access-denied-message h2 {
  margin: auto;
}

.access-denied-icon {
  width: 50px; /* Icon size */
  height: auto; /* Maintain aspect ratio */
  margin-bottom: 0.5rem; /* Space between icon and text */
}

.access-denied-message p {
  font-size: larger; /* Increased font size for better readability */
  margin-top: 0.5rem; /* Space above the paragraph */
}
</style>
