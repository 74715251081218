<template>
  <div
    class="banner div-background-image"
    :style="{
      backgroundImage: `linear-gradient(90deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 69.17%), url(${banner.imageUrl})`,
    }"
  >
    <div class="banner-contents">
      <div class="banner-title">{{ banner.title }}</div>
      <div class="main-deal-description">{{ banner.description }}</div>
      <div class="button-div">
        <a>
          <div class="button button-login hover-effect-noshadow" @click="login">
            {{ loginButtonLabel }}
          </div>
        </a>
        <a>
          <div
            class="button button-signup hover-effect-noshadow"
            @click="createAccount"
          >
            {{ createAccountButtonLabel }}
          </div>
        </a>
      </div>
    </div>
  </div>
  <div class="horizontal-page-margin vertical-page-margin">
    <ImageWithText
      class="section-margin"
      :imageUrl="downloadApp?.sectionImage?.fields?.file?.url"
      :leftAlignImage="false"
      :minHeight="`500px`"
    >
      <DownloadApp :fields="downloadApp"></DownloadApp>
    </ImageWithText>
    <ImageWithText
      class="section-margin"
      v-for="(feature, index) in otherFeatures"
      :key="index"
      :imageUrl="feature?.image?.fields?.file?.url"
      :leftAlignImage="!Boolean(index % 2)"
    >
      <div class="feature-title">{{ feature?.title }}</div>
      <RichText :document="feature?.descriptionRt"></RichText>
    </ImageWithText>
    <div class="section-margin bb-section">
      <div class="row">
        <div
          class="col-md-6"
          v-for="(item, index) in bottomBanner"
          :key="index"
        >
          <div
            class="div-icon-image bb-icon"
            :style="{
              backgroundImage: `url(${item?.icon?.fields?.file?.url})`,
            }"
          ></div>
          <div class="bb-heading">{{ item.title }}</div>
          <div class="bb-description">{{ item.description }}</div>
        </div>
      </div>
    </div>
    <DownloadApp class="section-margin" :fields="downloadApp"></DownloadApp>
  </div>
</template>

<script>
import DownloadApp from "@/components/fibr-portal/DownloadApp.vue";
import entryId from "@/config/entryid.json";
import { getEntryFromArray } from "@/util/contentUtil.js";
import ImageWithText from "@/components/common/ImageWithText.vue";
import RichText from "@/components/common/RichText.vue";

export default {
  name: "FibrPortal",
  inject: ["$contentful"],
  components: {
    DownloadApp,
    ImageWithText,
    RichText,
  },
  data() {
    return {
      loginButtonLabel: "Log in",
      createAccountButtonLabel: "Create an account",
      banner: {
        title: "",
        description: "",
        imageUrl: "",
        createAccountUrl: "",
        loginUrl: "",
      },
      downloadApp: {
        sectionImage: "",
      },
      otherFeatures: [],
      bottomBanner: [],
    };
  },
  created() {
    this.$contentful.getEntry(entryId.fibrPortal.content).then((val) => {
      const fields = val.fields.body;

      const bannerInfo = getEntryFromArray(fields, entryId.fibrPortal.header);
      this.banner.title = bannerInfo.title;
      this.banner.description = bannerInfo.description;
      this.banner.imageUrl = bannerInfo.backgroundImage?.fields?.file?.url;
      this.banner.createAccountUrl = bannerInfo.createAccountUrl;
      this.banner.loginUrl = bannerInfo.loginUrl;

      const downloadAppInfo = getEntryFromArray(
        fields,
        entryId.fibrPortal.downloadApp
      );
      this.downloadApp = downloadAppInfo;

      entryId.fibrPortal.otherFeatures.forEach((item) => {
        const featureInfo = getEntryFromArray(fields, item);
        this.otherFeatures.push(featureInfo);
      });

      entryId.fibrPortal.bottomBanner.forEach((item) => {
        const bottomBannerInfo = getEntryFromArray(fields, item);
        this.bottomBanner.push(bottomBannerInfo);
      });
    });
  },
  methods: {
    login() {
      window.location.href = this.banner.loginUrl;
    },
    createAccount() {
      window.location.href = this.banner.createAccountUrl;
    },
  },
};
</script>

<style scoped lang="scss">
.banner {
  min-height: 650px;
  display: flex;
}

.banner-contents {
  display: block;
  margin: auto;
  position: relative;
}

.banner-title {
  color: white;
  text-align: center;
  font-size: $font-xl;
  font-style: normal;
  font-weight: 700;
  line-height: 56px;
}

.main-deal-description {
  color: white;
  text-align: center;
  font-size: $font-s;
  font-style: normal;
  font-weight: 450;
  line-height: 24px;
  max-width: 550px;
  margin-top: 24px;
  margin-left: auto;
  margin-right: auto;
}

.button-div {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  margin-top: 32px;
}

.button {
  width: 155px;
  height: 32px;
  border-radius: 100px;
  text-align: center;
  font-size: $font-xs;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;

  &-login {
    border: 1px solid #ffc627;
    color: white;
    margin-right: 16px;
  }

  &-signup {
    background: #ffc627;
    color: #584106;
  }
}

.feature-title {
  color: #000;
  font-size: $font-l;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 16px;
}

.bb-section {
  position: relative;
  padding: 48px 0 48px 0;

  &:after {
    @include full-window-width;
    content: "";
    background-color: #fafafa;
    z-index: -1;
  }
}

.bb-icon {
  height: 32px;
  width: 32px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 8px;
}

.bb-heading {
  font-style: normal;
  font-weight: 700;
  font-size: $font-m;
  line-height: 32px;
  text-align: center;
  margin-bottom: 8px;
}

.bb-description {
  font-style: normal;
  font-weight: 450;
  font-size: $font-xs;
  line-height: 18px;
  text-align: center;
  max-width: 320px;
  margin-left: auto;
  margin-right: auto;
}
</style>
