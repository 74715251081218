<template>
  <div>
    <div class="row">
      <div class="col-md-4 feature" v-if="showTV">
        <div
          class="div-icon-image image"
          :style="{
            backgroundImage: `url(${bundleDetails?.tv?.image})`,
          }"
        ></div>
        <div class="title">{{ bundleDetails?.tv?.title }}</div>
        <div class="decription">{{ bundleDetails?.tv?.copy }}</div>
        <div
          class="benefits"
          v-for="(benefit, index) in bundleDetails?.tv?.highlights"
          :key="index"
        >
          <span
            class="div-icon-image icon"
            :style="{
              backgroundImage:
                'url(' + require('@/assets/icons/green_check.svg') + ')',
            }"
          ></span>
          <span class="benefit">{{ benefit }}</span>
        </div>
      </div>
      <div class="col-md-4 feature" v-if="showBroadband">
        <div
          class="div-icon-image image"
          :style="{
            backgroundImage: `url(${bundleDetails?.broadband?.image})`,
          }"
        ></div>
        <div class="title">{{ bundleDetails?.broadband?.title }}</div>
        <div class="decription">{{ bundleDetails?.broadband?.copy }}</div>
        <div
          class="benefits"
          v-for="(benefit, index) in bundleDetails?.broadband?.highlights"
          :key="index"
        >
          <span
            class="div-icon-image icon"
            :style="{
              backgroundImage:
                'url(' + require('@/assets/icons/green_check.svg') + ')',
            }"
          ></span>
          <span class="benefit">{{ benefit }}</span>
        </div>
      </div>
      <div class="col-md-4 feature" v-if="showVoice">
        <div
          class="div-icon-image image"
          :style="{
            backgroundImage: `url(${bundleDetails?.voice?.image})`,
          }"
        ></div>
        <div class="title">{{ bundleDetails?.voice?.title }}</div>
        <div class="decription">{{ bundleDetails?.voice?.copy }}</div>
        <div
          class="benefits"
          v-for="(benefit, index) in bundleDetails?.voice?.highlights"
          :key="index"
        >
          <span
            class="div-icon-image icon"
            :style="{
              backgroundImage:
                'url(' + require('@/assets/icons/green_check.svg') + ')',
            }"
          ></span>
          <span class="benefit">{{ benefit }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BundleCommonFeatures",
  inject: ["$contentful"],
  props: {
    bundleDetails: {
      type: Object,
      default: () => {},
    },
    showTV: {
      type: Boolean,
      default: true,
    },
    showBroadband: {
      type: Boolean,
      default: true,
    },
    showVoice: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped lang="scss">
.feature {
  padding-right: 40px;
}

.image {
  width: 280px;
  height: 214px;
}

.title {
  color: #635890;
  font-size: $font-s;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  margin-top: 24px;
}

.decription {
  color: black;
  font-size: $font-xs;
  font-style: normal;
  font-weight: 450;
  line-height: 20px;
  margin-top: 5px;
  margin-bottom: 10px;
}

.benefits {
  white-space: nowrap;
  display: flex;
  margin-bottom: 10px;
}

.icon {
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  vertical-align: top;
}

.benefit {
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  color: black;
  font-size: $font-xs;
  font-style: normal;
  font-weight: 450;
  line-height: 24px;
}
</style>
