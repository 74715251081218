<template>
  <div>
    <AlivHeader></AlivHeader>
    <BreadCrumbs class="horizontal-page-margin breadcrumbs"></BreadCrumbs>
    <div class="page-margin-top">
      <router-view></router-view>
    </div>
    <FibrNavContainer
      class="section-margin horizontal-page-margin vertical-page-margin"
    ></FibrNavContainer>
    <AlivFooter class="section-margin"></AlivFooter>
  </div>
</template>

<script>
import AlivHeader from "@/components/header/AlivHeader.vue";
import BreadCrumbs from "@/components/common/BreadCrumbs.vue";
import FibrNavContainer from "@/components/footer/FibrNavContainer.vue";
import AlivFooter from "@/components/footer/AlivFooter.vue";

export default {
  name: "App",
  components: {
    AlivHeader,
    BreadCrumbs,
    FibrNavContainer,
    AlivFooter,
  },
};
</script>

<style scoped lang="scss">
.breadcrumbs {
  top: 134px;
  left: 0;
  right: 0;
  z-index: 1;
}

@media only screen and (min-width: 576px) {
  .page-margin-top {
    margin-top: 112px;
  }
}
</style>
