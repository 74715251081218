<template>
  <div
    class="horizontal-page-margin vertical-page-margin section-margin-xlarge"
  >
    <div class="contents">
      <div class="title">
        {{ title }}
      </div>
      <div class="description">
        {{ description }}
      </div>
      <label class="section-margin-small" for="phone">{{ phoneLabel }}</label>
      <input type="tel" id="phone" :placeholder="phonePlaceholder" />
      <label class="section-margin-xsmall" for="password">{{
        passwordLabel
      }}</label>
      <input type="password" id="password" :placeholder="passwordPlaceholder" />
      <input id="remember" type="checkbox" />
      <label class="checkbox-label" for="remember">{{ rememberLabel }}</label>
      <div class="login-button hover-effect-noshadow section-margin-small">
        {{ loginLabel }}
      </div>
      <div class="forgot-password">{{ forgotLabel }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PortalLogin",
  inject: ["$contentful"],
  data() {
    return {
      title: "Welcome back",
      description:
        "Login to your myALIV app. Not an ALIV customer? Sign up for ALIV or ALIV FIbr.",
      callFeaturesLabel: "Call Features",
      phoneLabel: "Phone number",
      phonePlaceholder: "(555) 555 - 5555",
      passwordLabel: "Password",
      passwordPlaceholder: "Enter your password",
      rememberLabel: "Remember me",
      loginLabel: "login",
      forgotLabel: "Forgot password?",
    };
  },
};
</script>

<style scoped lang="scss">
.contents {
  max-width: 364px;
  margin-left: auto;
  margin-right: auto;
}

.title {
  color: #000;
  font-size: $font-xl;
  font-style: normal;
  font-weight: 700;
  line-height: 56px;
  text-align: center;
}

.description {
  color: #4f4f4f;
  text-align: center;
  font-size: $font-s;
  font-style: normal;
  font-weight: 450;
  line-height: 24px;
  margin-top: 16px;
}

label {
  font-size: $font-xxs;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

input[type="password"],
input[type="tel"] {
  width: 100%;
  padding: 12px 20px;
  border-radius: $border-radius;
  box-shadow: none;
  outline: none;
  border: 1px solid #2c2d3b;
  margin-top: 5px;
}

input[type="checkbox"] {
  border-radius: $border-radius;
  border: 1px solid #828282;
  margin-top: 16px;
}

.checkbox-label {
  margin-left: 8px;
  color: #828282;
  font-size: $font-xxs;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
}

.login-button {
  border-radius: 100px;
  background: #635890;
  padding: 15px;
  width: 100%;
  color: white;
  text-align: center;
}

.forgot-password {
  color: #635890;
  text-align: center;
  font-size: $font-s;
  font-style: normal;
  font-weight: 450;
  line-height: 20px;
  margin-top: 16px;
  cursor: pointer;
}
</style>
