<template>
  <div>
    <div class="heading-dark">{{ fields?.title }}</div>
    <div class="section-margin-small">
      <div class="row">
        <div
          v-for="item in fields?.images"
          :key="item?.sys?.id"
          class="col-md-4"
        >
          <div class="card-contents">
            <div
              class="item-icon div-icon-image"
              :style="{
                backgroundImage: `url(${item?.fields?.image?.fields?.file?.url})`,
              }"
            ></div>
            <div class="item-title">{{ item?.fields?.title }}</div>
          </div>
        </div>
      </div>
    </div>
    <RichText
      class="section-margin-small additional-copy"
      v-if="showAdditionalCopy"
      :document="fields?.additionalCopy"
    ></RichText>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import entryId from "@/config/entryid.json";
import RichText from "./RichText.vue";

export default defineComponent({
  name: "BundleHardware",
  inject: ["$contentful"],
  components: {
    RichText,
  },
  props: {
    showAdditionalCopy: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      fields: {},
    };
  },
  created() {
    this.$contentful.getEntry(entryId.fibrBundles.hardware).then((val) => {
      this.fields = val.fields;
    });
  },
});
</script>

<style scoped lang="scss">
.item-icon {
  text-align: center;
  margin-bottom: 5px;
  height: 200px;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
}

.item-title {
  color: #000;
  text-align: center;
  font-size: $font-s;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.additional-copy {
  color: #9b51e0;
  text-align: center;
  font-size: $font-xs;
  font-style: normal;
  font-weight: 450;
  line-height: 20px;
}
</style>
